import * as d3 from "d3";

type Point = { px: number; py: number };

export class Lupa {
  circle: any;
  lupa: any;
  image: any;
  constructor(private idClipPath: string) {
    console.log("HEEERE");
    this.circle = d3.select("#clippedLupa").select("circle");
    this.image = d3.select("#idImg");
    this.lupa = d3.select("g.lupa-cursor");
    console.log("Lupa -> constructor -> lupa", this.lupa);
    this.SubscribeToSVGMouseMove();
  }

  public turnOff = () => {
    this.UnSubscribeToSVGMouseMove();
  };
  public turnOn = () => {
    this.SubscribeToSVGMouseMove();
  };

  public moveLupaTo = (point: Point) => {
    console.log("🚀 ~ Lupa ~ point:", point);
    this.circle
      // .transition()
      // .duration(100)
      // .ease(d3.easeLinear)
      .attr("cx", point.px)
      .attr("cy", point.py);

    // Redraw the image with the updated clipping
    this.image.attr("clip-path", "url(#clippedLupa)");

    const deltaX = -135; // // 175.20928955078125px, 786.2642211914062px // translate(-78px, 519px) scale(19.5)
    const deltaY = -135;

    this.lupa.attr(
      "style",
      "transform: translate(" + (point.px + deltaX) + "px, " + (point.py + deltaY) + "px) scale(10.5);"
    );
  };

  private SubscribeToSVGMouseMove = () => {
    const svg = d3.select("body");
    const handleMouseMove = (event: MouseEvent) => {
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      this.moveLupaTo({ px: mouseX, py: mouseY });
    };
    svg.on("pointermove", handleMouseMove);
  };

  private UnSubscribeToSVGMouseMove = () => {
    const svg = d3.select("body");

    svg.on("pointermove", null);
  };
}

class SvgMouseMove {
  constructor() {
    const svg = d3.select("#SVG-background");
  }
}
