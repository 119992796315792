import { useState, useEffect } from "react";
import * as Types from "./types";
import * as helpers from "./helpers";

export const useDefiningElements = () => {
  const [points, setPoints] = useState<Types.Point[]>([]);

  useEffect(() => {
    const element = document.getElementById("SVG-background") as HTMLElement;
    // element.addEventListener("click", onClick);

    helpers.setClickEvent(element, (params: Types.Point) => {
      console.log("useDefiningElements -> params", params);
      setPoints((pts) => [...pts, params]);
    });
  }, []);

  return points;
};
