const PIZARRA = [
  [74, 314],
  [91, 579],
  [469, 551],
  [476, 313],
].map(([px, py]) => ({ px, py }));

export default {
  pizarra: {
    points: PIZARRA,
    configs: { backgroundColor: "white", strokeWidth: "1", filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));" },
  },
};
