import * as THREE from "three";
import { LOGO_COLORS } from "../helpers";

interface ILights {
  updateMouseLightPosition: (position: THREE.Vector3) => void;
  addLights: () => void;
}

type LightsSettings = {
  showHelpers: boolean;
};

class LightsImp implements ILights {
  private mouseLight: THREE.PointLight;
  private settings: LightsSettings;

  constructor(private scene: THREE.Scene, showHelpers = false) {
    this.mouseLight = new THREE.PointLight(LOGO_COLORS.square, 1000);
    this.settings = {
      showHelpers,
    };
  }

  public addLights() {
    // const ambientLight = new THREE.AmbientLight(LOGO_COLORS.origin, 7);
    const ambientLight = new THREE.AmbientLight(LOGO_COLORS.origin, 100);
    this.scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 10);
    directionalLight.position.set(0, 1, 1);
    this.scene.add(directionalLight);

    this.mouseLight.position.set(20, 20, 20);
    this.scene.add(this.mouseLight);

    const pointLightSUN = new THREE.PointLight(0xffffff, 50);
    pointLightSUN.position.set(5, 5, 5);
    this.scene.add(pointLightSUN);

    const pointLightSUN2 = new THREE.PointLight(0xffffff, 50);
    pointLightSUN2.position.set(0, 2, 9);
    this.scene.add(pointLightSUN2);
    this.settings.showHelpers && this._renderHelper(pointLightSUN2);

    const pointLightSUN3 = new THREE.PointLight(0xffffff, 50);
    pointLightSUN3.position.set(10, 0, 2);
    this.scene.add(pointLightSUN3);
    this.settings.showHelpers && this._renderHelper(pointLightSUN3);

    const pointLightSUN4 = new THREE.PointLight(0xffffff, 50);
    pointLightSUN4.position.set(10, 10, 0);
    this.scene.add(pointLightSUN4);
    this.settings.showHelpers && this._renderHelper(pointLightSUN4);

    const pointLightOrigin = new THREE.PointLight(LOGO_COLORS.square, 100);
    pointLightOrigin.position.set(10, 10, 2);
    this.scene.add(pointLightOrigin);
  }

  public updateMouseLightPosition(position: THREE.Vector3) {
    this.mouseLight.position.copy(position);
  }

  private _renderHelper(light: THREE.PointLight) {
    const helper = new THREE.PointLightHelper(light, 1);
    this.scene.add(helper);
  }
}

export default LightsImp;
