export default {
  en: {
    title: "Elements",
  },
  es: {
    title: "Elementos",
  },
  de: {
    title: "Dinge",
  },
};
