const MONITOR_SCREEN = [
  [554, 443],
  [554, 553],
  [585, 555],
  [629, 559],
  [674, 565],
  [713, 571],
  [760, 583],
  [808, 595],
  [828, 601],
  [844, 608],
  [877, 471],
  [839, 464],
  [758, 457],
  [686, 450],
  [639, 445],
  [583, 441],
].map(([px, py]) => ({ px, py }));

export default {
  monitorScreen: {
    points: MONITOR_SCREEN,
    configs: { backgroundColor: "gray", strokeWidth: "1", filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));" },
  },
};
