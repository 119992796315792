const buildLogoPoints = (l: number = 100, withDeviation: boolean = false) => {
  const [x0, y0] = [0, 0];
  const [x1, y1] = [-l / 2, (l / 2) * (1 / Math.sqrt(3))]; // Math.atan(30) === raiz(3)/3
  const [x2, y2] = [x1 + l, y1];
  const [x3, y3] = [x0, -l / Math.sqrt(3)];
  const [x4, y4] = [x3, y1];
  const [x5, y5] = [l / 4, y3 + (l / 2) * (Math.sqrt(3) / 2)]; // Math.cos(30)];
  const [x6, y6] = [-l / 4, y5];
  const [x7, y7] = [x6 / 2, y1 - (l / 4) * (Math.sqrt(3) / 2)];
  const [x8, y8] = [x5 / 2, y7]; // Math.cos(30)];
  const [x9, y9] = [x0, y3 + (l / 2) * (Math.sqrt(3) / 2)];
  const [x10, y10] = [x6, (x6 * y1) / x1, y5];
  const [x11, y11] = [x5, (x5 * y2) / x2];
  const [x12, y12] = [x0, y6 - y10];

  const randomDeviation = () => {
    return withDeviation ? 1 + (Math.random() * 2 - 1) / 10 : 1;
  };

  const [p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12] = [
    [randomDeviation() * x0, randomDeviation() * y0],
    [randomDeviation() * x1, randomDeviation() * y1],
    [randomDeviation() * x2, randomDeviation() * y2],
    [randomDeviation() * x3, randomDeviation() * y3],
    [randomDeviation() * x4, randomDeviation() * y4],
    [randomDeviation() * x5, randomDeviation() * y5],
    [randomDeviation() * x6, randomDeviation() * y6],
    [randomDeviation() * x7, randomDeviation() * y7],
    [randomDeviation() * x8, randomDeviation() * y8],
    [randomDeviation() * x9, randomDeviation() * y9],
    [randomDeviation() * x10, randomDeviation() * y10],
    [randomDeviation() * x11, randomDeviation() * y11],
    [randomDeviation() * x12, randomDeviation() * y12],
  ];

  const trianguloExteriorValues = [p1, p2, p3, p1];

  const triangulo2 = [p4, p5, p6, p4];

  const triangulo3 = [p7, p8, p9, p7];

  const trianguloExterior = {
    exterior: trianguloExteriorValues,
    left: [p0, p3, p1, p0],
    bottom: {
      exterior: [p0, p1, p2, p0],
      interiorDerecha: [p4, p2, p11, p4],
    },
    right: {
      exterior: [p0, p2, p3, p0],
      interiorDerecha: [p2, p5, p11, p2],
      extCornerRightBottomBorder: [p4, p2, p5, p11, p4],
    },
  };

  const triangulosCuadrado = {
    left: [p10, p4, p6, p10],
    right: [p4, p11, p5, p4],
    top: [p5, p12, p6, p5],
  };

  const innerSquares = {
    left: [p0, p12, p6, p10, p0],
    bottom: [p0, p10, p4, p11, p0],
    right: [p0, p11, p5, p12, p0],
  };

  const triangulosSwap = {
    top: [p3, p7, p1, p9, p7, p0, p3],
    middle: {
      outter: [p3, p0, p1, p8, p3],
      inner: [p9, p0, p7, p8, p9],
      middle: [p12, p0, p10, p8, p12],
    },
    bottom: [p3, p8, p1, p11, p3],
  };

  const letters = {
    l: [p3, p10, p11],
    // o: scalate(0.75, [p12, p6, p10, p4, p11, p5, p12]),
    o: [p12, p6, p10, p4, p11, p5, p12],
    a: scalate(0.9, [p4, p7, p6, p9, p7, p9, p5]),
    d: [p6, p11, p12, p6],
    i: [p4, p0],
    n: [p10, p6, p11, p5],
    // g: [...scalate(0.5, [p12, p6, p10, p4, p11, p5, p12]), ...scalate(1.5, [p9, p6, p7, p0, p9, p0, p4, p10])], //[p9, p6, p7, p0, p9, p0, p4, p10],
    // g: scalate(1.5, [p9, p6, p7, p0, p9, p0, p4, p10]), //[p9, p6, p7, p0, p9, p0, p4, p10],
    g: [p9, p6, p7, p0, p9, p0, p4, p10], //[p9, p6, p7, p0, p9, p0, p4, p10],
    c: [p12, p6, p10, p4, p11],
    r: [p4, p7, p6, p9, p0, p8, p0, p7],
    w: [p6, p7, p0, p8, p5],
    e: [p12, p6, p7, p0, p7, p4, p11],
    m: [p10, p6, p0, p5, p11],
    s: [p9, p6, p7, p0, p8, p4, p10],
  };

  const loading = {
    l: letters.l,
    o: letters.o,
    a: letters.a,
    d: letters.d,
    i: letters.i,
    n: letters.n,
    g: letters.g,
  };

  const cargando = {
    c: letters.c,
    a: letters.a,
    r: letters.r,
    g: letters.g,
    n: letters.n,
    d: letters.d,
    o: letters.o,
  };

  const welcome = {
    w: letters.w,
    e: letters.e,
    l: letters.l,
    c: letters.c,
    o: letters.o,
    m: letters.m,
  };

  const ideas = {
    i: letters.i,
    d: letters.d,
    e: letters.e,
    a: letters.a,
    s: letters.s,
  };

  const innerSmallTriangle = {
    triangle: triangulo3,
    laterals: {
      left: [p0, p9, p7, p0],
      bottom: [p0, p7, p8, p0],
      right: [p0, p8, p9, p0],
    },
  };

  const figures = {
    t1: [p1, p4, p10, p1],
    t2: [p4, p2, p11, p4],
    t3: [p2, p5, p11, p2],
    t4: [p3, p5, p12, p3],
    t5: [p3, p12, p6, p3],
    t6: [p1, p10, p6, p1],
    t7: [p10, p6, p7],
    t8: [p10, p4, p7],
    t9: [p11, p4, p8],
    t10: [p11, p5, p8],
    t11: [p12, p5, p9],
    t12: [p12, p6, p9],
    t13: [p6, p7, p9, p6],
    t14: [p4, p8, p7, p4],
    t15: [p5, p9, p8, p5],
    t16: [p9, p7, p8, p9],
  }; // all figures to be used to properlly draw the logo when a deviation is applied.

  return {
    trianguloExterior,
    triangulo2,
    triangulosCuadrado,
    triangulo3,
    innerSquares,
    loading,
    innerSmallTriangle,
    figures,
    cargando,
    welcome,
    ideas,
    triangulosSwap,
  };
};

// Function to calculate the rotation matrix for rotating around an arbitrary axis by an angle theta
function rotationMatrix(axis: number[], theta: number): number[][] {
  const [x, y, z] = axis;
  const cosT = Math.cos(theta);
  const sinT = Math.sin(theta);
  const oneMinusCosT = 1 - cosT;

  // Rodrigues' rotation formula
  const rotMatrix: number[][] = [
    [cosT + x * x * oneMinusCosT, x * y * oneMinusCosT - z * sinT, x * z * oneMinusCosT + y * sinT],
    [y * x * oneMinusCosT + z * sinT, cosT + y * y * oneMinusCosT, y * z * oneMinusCosT - x * sinT],
    [z * x * oneMinusCosT - y * sinT, z * y * oneMinusCosT + x * sinT, cosT + z * z * oneMinusCosT],
  ];

  return rotMatrix;
}

// Function to rotate a point around a specified axis by an angle
function rotatePoint(point: number[], axis: number[], theta: number): number[] {
  const rotMatrix = rotationMatrix(axis, theta);
  const [x, y, z] = point;

  // Apply rotation matrix to the point
  const xp = rotMatrix[0][0] * x + rotMatrix[0][1] * y + rotMatrix[0][2] * z;
  const yp = rotMatrix[1][0] * x + rotMatrix[1][1] * y + rotMatrix[1][2] * z;
  const zp = rotMatrix[2][0] * x + rotMatrix[2][1] * y + rotMatrix[2][2] * z;

  return [xp, yp, zp];
}

// Function to rotate all points in the logo3dPoints array around the axis by the angle theta
function rotateLogo3dPoints(
  points: { [key: string]: number[] },
  axis: number[],
  theta: number
): { [key: string]: number[] } {
  const rotatedPoints: { [key: string]: number[] } = {};

  // Rotate each point
  for (const [key, point] of Object.entries(points)) {
    rotatedPoints[key] = rotatePoint(point, axis, theta);
  }

  return rotatedPoints;
}

export const logo3dPoints = (l: number = 100) => {
  const pointsToRotate = {
    p0: [0, 0, 0],
    p1: [0, 0, l],
    p2: [l, 0, 0],
    p3: [0, l, 0],
    p4: [l / 2, 0, l / 2],
    p5: [l / 2, l / 2, 0],
    p6: [0, l / 2, l / 2],
    p7: [l / 4, l / 4, l / 2],
    p7p: [0, 0, l / 4],
    p8: [l / 2, l / 4, l / 4],
    p8p: [l / 4, 0, 0],
    p9: [l / 4, l / 2, l / 4],
    p9p: [0, l / 4, 0],
    p10: [0, 0, l / 2],
    p11: [l / 2, 0, 0],
    p12: [0, l / 2, 0],
  };

  const axis = [1 / Math.sqrt(3), 1 / Math.sqrt(3), 1 / Math.sqrt(3)]; // Normalized axis vector (1,1,1)
  const theta = Math.PI / 6; // 30 degrees in radians

  const rotatedPoints = rotateLogo3dPoints(pointsToRotate, axis, theta);

  const points = rotatedPoints;

  return {
    exterior: {
      left: {
        bottom: [points.p6, points.p1, points.p10, points.p6],
        top: [points.p6, points.p12, points.p3, points.p6],
      },
      bottom: {
        left: [points.p1, points.p4, points.p10, points.p1],
        right: [points.p4, points.p2, points.p11, points.p4],
      },
      right: {
        bottom: [points.p2, points.p5, points.p11, points.p2],
        top: [points.p5, points.p3, points.p12, points.p5],
      },
    },
    square: {
      triangleLeft: [points.p10, points.p4, points.p6, points.p10],
      triangleRight: [points.p4, points.p11, points.p5, points.p4],
      triangleTop: [points.p5, points.p12, points.p6, points.p5],
    },
    inner: {
      triangle: [points.p7, points.p8, points.p9, points.p7],
      laterals: {
        left: [points.p9, points.p6, points.p7, points.p9],
        bottom: [points.p7, points.p4, points.p8, points.p7],
        right: [points.p8, points.p5, points.p9, points.p8],
      },
    },
    origin: {
      triangle: [points.p7p, points.p8p, points.p9p, points.p7p],
      laterals: {
        left: [points.p0, points.p7p, points.p9p, points.p0],
        bottom: [points.p0, points.p7p, points.p8p, points.p0],
        right: [points.p0, points.p8p, points.p9p, points.p0],
      },
    },
  };
};

export const logoPoints = (l: number = 100, withDeviation: boolean = false) => {
  const { trianguloExterior, triangulo2, triangulosCuadrado, triangulo3, figures, triangulosSwap } = buildLogoPoints(
    l,
    withDeviation
  );

  return [
    {
      points: trianguloExterior.left,
      className: "bg_triangle-left",
    },
    {
      points: trianguloExterior.bottom.exterior,
      className: "bg_triangle-bottom",
    },
    {
      points: trianguloExterior.right.exterior,
      className: "bg_triangle-right",
    },
    // {
    //   points: trianguloExterior.exterior,
    //   className: "bg",
    // },
    {
      points: figures.t1,
      className: "bg_left",
    },
    {
      points: figures.t2,
      className: "bg_left",
    },
    {
      points: figures.t3,
      className: "bg_left",
    },
    {
      points: figures.t4,
      className: "bg_left",
    },
    {
      points: figures.t5,
      className: "bg_left",
    },
    {
      points: figures.t6,
      className: "bg_left",
    },
    {
      points: figures.t7,
      className: "sqr-triangle",
    },
    {
      points: figures.t8,
      className: "sqr-triangle",
    },
    {
      points: figures.t9,
      className: "sqr-triangle",
    },
    {
      points: figures.t10,
      className: "sqr-triangle",
    },
    {
      points: figures.t11,
      className: "sqr-triangle",
    },
    {
      points: figures.t12,
      className: "sqr-triangle",
    },
    {
      points: figures.t13,
      className: "outer-triangle",
    },
    {
      points: figures.t14,
      className: "outer-triangle",
    },
    {
      points: figures.t15,
      className: "outer-triangle",
    },
    {
      points: figures.t16,
      className: "inner-triangle",
    },
    // {
    //   points: triangulo2,
    //   className: "outer-triangle",
    // },
    // {
    //   points: triangulosCuadrado.left,
    //   className: "sqr-triangle left",
    // },
    // {
    //   points: triangulosCuadrado.right,
    //   className: "sqr-triangle right",
    // },
    // {
    //   points: triangulosCuadrado.top,
    //   className: "sqr-triangle top",
    // },
    // {
    //   points: triangulo3,
    //   className: "inner-triangle",
    // },
    // {
    //   points: triangulosSwap.outter,
    //   className: "swap-triangle__outter",
    // },
    // {
    //   points: triangulosSwap.inner,
    //   className: "swap-triangle__inner",
    // },
  ];
};

export const arrowPoints = (l: number = 100) => {
  const { trianguloExterior } = buildLogoPoints(l);

  return [
    {
      points: trianguloExterior.bottom.interiorDerecha,
      className: "bg_botton right",
    },
    {
      points: trianguloExterior.right.interiorDerecha,
      className: "bg_right right",
    },
    {
      points: trianguloExterior.right.extCornerRightBottomBorder,
      className: "bg_right right-border",
    },
  ];
};

export const SwapArrowPoints = (l: number = 100) => {
  const { triangulosSwap } = buildLogoPoints(l);

  return [
    // {
    //   points: triangulosSwap.top,
    //   className: "swap-triangle__outter",
    // },
    // {
    //   points: triangulosSwap.middle.outter,
    //   className: "swap-triangle__outter",
    // },
    // {
    //   points: triangulosSwap.middle.middle,
    //   className: "swap-triangle__outter",
    // },
    {
      points: triangulosSwap.middle.inner,
      className: "swap-triangle__inner",
    },
    // {
    //   points: triangulosSwap.bottom,
    //   className: "swap-triangle__inner",
    // },
  ];
};

const calculateLetterWidth = (letterPoints: number[][], rotation: number = 0) => {
  return false;
  // It is missing correct the calculation.
  const pointsToUse = letterPoints.map(([x, y]) => {
    return [x * Math.cos((rotation * Math.PI) / 180) + y * Math.sin((rotation * Math.PI) / 180), y];
  });

  const { min, max } = pointsToUse.reduce(
    (acc, current) => ({
      min: Math.min(acc.min, current[0]),
      max: Math.max(acc.max, current[0]),
    }),
    { min: Infinity, max: -Infinity }
  );

  return max - min;

  return Math.abs((max - min) * Math.cos((rotation * Math.PI) / 90));
};

const cargandoPoints = (l: number = 100) => {
  const { loading } = buildLogoPoints(l);
};

type TpWordsDefined = "loading" | "cargando" | "welcome" | "ideas";

export const loadingPoints = (l: number = 100, word: TpWordsDefined) => {
  const { loading, cargando, welcome, ideas } = buildLogoPoints(l);
  const lettersSpace = l * 0.2;
  const [x0, y0] = [l * 0.5, l * 0.5];

  const dotData = {
    letter: "dot",
    points: scalate(0.2, loading.o),
    // points: loading.o,
    width: l * 0.02,
    // width: calculateLetterWidth(scalate(0.2, loading.o)),
    deltaY: l / 5,
    rotation: 0,
  };

  const wordLoading = [
    {
      letter: "l",
      points: loading.l,
      width: l * 0.3,
      // width: calculateLetterWidth(loading.l),
      deltaY: l / 12,
      rotation: 0,
    },
    {
      letter: "o",
      points: scalate(0.75, loading.o),
      // points: loading.o,
      width: l * 0.3,
      // width: calculateLetterWidth(scalate(0.75, loading.o), 30),
      deltaY: 0,
      rotation: 30,
    },
    {
      letter: "a",
      points: loading.a,
      width: l * 0.15,
      // width: calculateLetterWidth(loading.a, 60),
      deltaY: l * 0.1,
      rotation: 60,
    },
    {
      letter: "d",
      points: loading.d,
      width: l * 0.15,
      // width: calculateLetterWidth(loading.d, 40),
      deltaY: l / 12,
      rotation: 40,
    },
    {
      letter: "i",
      points: loading.i,
      width: l * 0.25,
      // width: calculateLetterWidth(loading.i),

      deltaY: -l * 0.1,
      rotation: 0,
    },
    {
      letter: "n",
      points: loading.n,
      width: l * 0.6,
      // width: calculateLetterWidth(loading.n),

      deltaY: l / 18,
      rotation: 0,
    },
    {
      letter: "g",
      points: scalate(1.5, loading.g),
      // points: loading.g,
      width: l * 0.05,
      // width: calculateLetterWidth(scalate(1.5, loading.g)),
      deltaY: l / 7,
      rotation: 0,
    },
    dotData,
    dotData,
    dotData,
  ];

  const worldIdeasData = [
    {
      letter: "I",
      points: scalate(2.2, ideas.i),
      width: l * 0.02,
      // width: calculateLetterWidth(loading.l),
      deltaY: -l / 2.5,
      rotation: 0,
    },
    {
      letter: "d",
      points: scalate(1, ideas.d),
      // points: loading.o,
      width: l * 0.3,
      // width: calculateLetterWidth(scalate(0.75, loading.o), 30),
      deltaY: 0,
      rotation: 60,
    },
    {
      letter: "e",
      points: scalate(0.75, ideas.e),
      width: l * 0.25,
      // width: calculateLetterWidth(loading.a, 60),
      deltaY: 0,
      rotation: 30,
    },
    {
      letter: "a",
      points: ideas.a,
      width: l * 0.35,
      // width: calculateLetterWidth(loading.d, 40),
      deltaY: l / 12,
      rotation: 60,
    },
    {
      letter: "s",
      points: ideas.s,
      width: l * 0.25,
      // width: calculateLetterWidth(loading.i),

      deltaY: 0,
      rotation: 0,
    },
    dotData,
    dotData,
    dotData,
  ];

  const wordCargandoData = [
    {
      letter: "c",
      points: cargando.c,
      width: l * 0.2,
      deltaY: 0,
      rotation: 30,
    },
    {
      letter: "a",
      points: cargando.a,
      width: l * 0.3,
      // width: calculateLetterWidth(scalate(0.75, loading.o), 30),
      deltaY: l / 10,
      rotation: 60,
    },
    {
      letter: "r",
      points: scalate(1, cargando.r),
      width: l * 0.35,
      // width: calculateLetterWidth(loading.a, 60),
      deltaY: l / 15,
      rotation: 30,
    },
    {
      letter: "g",
      points: scalate(1.2, cargando.g),
      width: l * 0.15,
      // width: calculateLetterWidth(loading.d, 40),
      deltaY: l / 20,
      rotation: 0,
    },
    {
      letter: "a",
      points: cargando.a,
      width: l * 0.4,
      // width: calculateLetterWidth(loading.i),
      deltaY: l / 10,
      rotation: 60,
    },
    {
      letter: "n",
      points: scalate(1, cargando.n),
      width: l * 0.2,
      deltaY: l / 20,
      rotation: 0,
    },
    {
      letter: "d",
      points: scalate(1, cargando.d),
      // points: loading.g,
      width: l * 0.35,
      deltaY: l / 10,
      rotation: 60,
    },
    {
      letter: "o",
      points: scalate(0.7, cargando.o),
      width: l * 0.25,
      deltaY: l / 10,
      rotation: 0,
    },
    dotData,
    dotData,
    dotData,
  ];

  const wordWelcomeData = [
    {
      letter: "w",
      points: scalate(2, welcome.w),
      width: l * 0.5,
      // width: calculateLetterWidth(loading.l),
      deltaY: l / 12,
      rotation: 0,
    },
    {
      letter: "e",
      points: scalate(0.75, welcome.e),
      // points: loading.o,
      width: l * 0.15,
      // width: calculateLetterWidth(scalate(0.75, loading.o), 30),
      deltaY: l / 20,
      rotation: 30,
    },
    {
      letter: "l",
      points: scalate(0.6, welcome.l),
      width: l * 0.2,
      // width: calculateLetterWidth(loading.a, 60),
      deltaY: l * 0.17,
      rotation: 0,
    },
    {
      letter: "c",
      points: scalate(0.8, welcome.c),
      width: l * 0.2,
      // width: calculateLetterWidth(loading.d, 40),
      deltaY: l / 12,
      rotation: 30,
    },
    {
      letter: "o",
      points: scalate(0.75, welcome.o),
      width: l * 0.35,
      // width: calculateLetterWidth(loading.i),
      deltaY: l * 0.1,
      rotation: 0,
    },
    {
      letter: "m",
      points: welcome.m,
      width: l * 0.3,

      deltaY: l / 6,
      rotation: 0,
    },
    {
      letter: "e",
      points: scalate(0.75, welcome.e),
      // points: loading.g,
      width: l * 0.25,
      deltaY: l / 7,
      rotation: 30,
    },

    dotData,
    dotData,
    dotData,
  ];

  let palabraToRenderData = wordLoading;

  switch (word) {
    case "loading":
      palabraToRenderData = wordLoading;
      break;
    case "cargando":
      palabraToRenderData = wordCargandoData;
      break;
    case "welcome":
      palabraToRenderData = wordWelcomeData;
      break;
    case "ideas":
      palabraToRenderData = worldIdeasData;
      break;

    default:
      break;
  }

  return palabraToRenderData.map(({ points, letter, deltaY, rotation }, i) => {
    const deltaX =
      i === 0
        ? 0
        : palabraToRenderData.reduce((acc, value, index) => {
            if (index >= i) return acc;
            return acc + value.width;
          }, 0);
    return {
      points: points,
      className: "loading " + letter,
      translations: {
        x: x0 + lettersSpace * i + deltaX,
        y: y0 + deltaY,
        rotation,
      },
    };
  });
};

export const innerSquarePoints = (l: number = 100) => {
  const { innerSquares, triangulo2, triangulosCuadrado } = buildLogoPoints(l);

  return [
    {
      points: innerSquares.bottom,
      className: "bg_botton",
    },
    {
      points: innerSquares.right,
      className: "bg_right",
    },
    {
      points: innerSquares.left,
      className: "bg_left",
    },
    {
      points: triangulo2,
      className: "triangle",
    },
    {
      points: triangulosCuadrado.left,
      className: "sqr-triangle left",
    },
    {
      points: triangulosCuadrado.right,
      className: "sqr-triangle right",
    },
    {
      points: triangulosCuadrado.top,
      className: "sqr-triangle top",
    },
  ];
};

export const innerSmallTrianglePoints = (l: number = 100) => {
  const { innerSmallTriangle } = buildLogoPoints(l);

  return [
    {
      points: innerSmallTriangle.laterals.bottom,
      className: "bg_bottom",
    },
    {
      points: innerSmallTriangle.laterals.right,
      className: "bg_right",
    },
    {
      points: innerSmallTriangle.laterals.left,
      className: "bg_left",
    },
    {
      points: innerSmallTriangle.triangle,
      className: "triangle",
    },
  ];
};

export const LoadingLogoPoints = (l: number = 100) => {
  const { innerSquares, triangulo2, triangulosCuadrado } = buildLogoPoints(l);

  return [
    {
      points: innerSquares.bottom,
      className: "bg_bottom",
    },
    {
      points: innerSquares.right,
      className: "bg_right",
    },
    {
      points: innerSquares.left,
      className: "bg_left",
    },
    {
      points: triangulo2,
      className: "triangle",
    },
    {
      points: triangulosCuadrado.left,
      className: "sqr-triangle left",
    },
    {
      points: triangulosCuadrado.right,
      className: "sqr-triangle right",
    },
    {
      points: triangulosCuadrado.top,
      className: "sqr-triangle top",
    },
  ];
};

export const scalate = (value: number, points: number[][]) => {
  return points.map((point) => {
    return [point[0] * value, point[1] * value];
  });
};

export const animateDotJump = (animationStatuses: boolean[], points: number[][]) => {
  return points;
};
