import * as THREE from "three";

import EnvTexture from "assets/textures/disturb.jpg";
import GoldMetalTexture from "assets/textures/gold_metal.jpg";
import SilverMetalTexture from "assets/textures/silver_metal.jpg";
// import Img from "assets/defined_fotos/profile.jpg"; // 2512 × 1616
// import EnvTexture from "assets/defined_fotos/desktop_mobile.jpeg";
import Me from "assets/characters/me_creation.png";
import Img from "assets/defined_fotos/logo_creation_min.jpg";
// import EnvTexture from "assets/defined_fotos/logo_creation_min.jpg";
// import EnvTexture from "assets/defined_fotos/original_idea_3024x4032.jpg";
// import EnvTexture from "assets/defined_fotos/books_corner_996x1327.png";
// import Texture from "assets/brick_bump.jpg";
// import Texture from "assets/brick_diffuse.jpg";
// import TextureSphere from "assets/golfball.jpg";
// import TextureSphere from "assets/kandao3.jpg";
// import TextureSphere from "assets/moon_1024.jpg";
// import TextureSphere from "assets/earth_atmos_2048.jpg";
import TextureSphere from "assets/textures/earth_atmos_4096.jpg";
import Ocean from "assets/textures/ocean.jpg";
import White from "assets/textures/white.png";

// import Texture from "assets/hardwood2_bump.jpg";
// import Texture from "assets/water.jpg";
// import Texture from "assets/grasslight-big.jpg";

// classs texture which load the textures and set the space colors:

interface ITextures {
  gold: THREE.Texture;
  silver: THREE.Texture;
  logoCreation: THREE.Texture;
  earth: THREE.Texture;
  me: THREE.Texture;
}

class Textures implements ITextures {
  gold: THREE.Texture;
  logoCreation: THREE.Texture;
  earth: THREE.Texture;
  me: THREE.Texture;
  silver: THREE.Texture;

  constructor() {
    this.gold = new THREE.TextureLoader().load(GoldMetalTexture);
    // this.logoCreation = new THREE.TextureLoader().load(Img);
    // this.logoCreation = new THREE.TextureLoader().load(Ocean);
    this.logoCreation = new THREE.TextureLoader().load(White);
    this.earth = new THREE.TextureLoader().load(TextureSphere);
    this.me = new THREE.TextureLoader().load(Me);
    this.silver = new THREE.TextureLoader().load(SilverMetalTexture);
    this._setColorSpace();
  }

  /** Private */
  _setColorSpace = () => {
    this.gold.colorSpace = THREE.DisplayP3ColorSpace;
    this.logoCreation.colorSpace = THREE.DisplayP3ColorSpace;
    this.earth.colorSpace = THREE.DisplayP3ColorSpace;
    this.me.colorSpace = THREE.DisplayP3ColorSpace;
    this.silver.colorSpace = THREE.DisplayP3ColorSpace;
  };
}

export default new Textures();
