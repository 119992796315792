import React, { useEffect } from "react";
import { ThreeTs } from ".";
import "./logo.scss";

import { logo3dPoints } from "components/Logo/helpers";

const Logo3d = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const threeTs = new ThreeTs(
      {
        htmlElement: containerRef.current,
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
        // width: containerRef.current.clientWidth,
        // height: containerRef.current.clientHeight,
      },
      logo3dPoints(10) as any
    );

    // Create a script element
    const script = document.createElement("script");

    // Set the source of the script to the URL you want to load
    script.src = "/scripts/fluid.js";

    // Optional: add attributes like async or defer
    script.async = true;

    // Append the script to the body or a specific element
    document.body.appendChild(script);

    // for the body element add this class: with-bg-image
    document.body.classList.add("with-bg-image");

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <canvas
        id="fluidCanvas"
        style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100vh" }}
      ></canvas>
      {/* <div ref={containerRef} style={{ width: "100%", height: "100vh", background: "rgb(19 9 0)" }} /> */}
      <div ref={containerRef} className="logo-3d-container" />
    </>
    // <div ref={containerRef} style={{ width: "100%", height: "100vh", background: "rgb(255 255 255)" }}></div>
    // <div ref={containerRef} style={{ width: "100%", height: "100vh", background: "white" }}></div>
    // <div ref={containerRef} style={{ width: "100%", height: "100vh", background: "black" }}></div>
  );
};

export default Logo3d;
