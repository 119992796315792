import React, { useState, useEffect } from "react";
import { Title } from "components/Texts";
import { useLocal } from "services/translate";
import LOCALS from "./locals";
import * as Types from "../types";
import { Visibility } from "./Visibility";
import { Button, Icon } from "semantic-ui-react";
import { Checkbox as CheckboxSemantic } from "semantic-ui-react";
import { Lupa } from "./services/lupa";
import { BtnsDownload, BtnLupa } from "./Btns";

import "./styles.scss";

type Props = {
  svgObjects: Types.SvgObjects;
  handlerExport: () => void;
  activateCreatingPoint: () => void;
  actDeactAnimated: () => void;
  onChangeSvgSectionSwitch: (keyTarget: string) => void;
  onChangeSvgMaskSwitch: (sectionTarget: string, keyTarget: string) => void;
  hideShowImage: () => void;
  initOpenState: boolean;
};

type TpLupa = {
  activated: boolean;
  service?: Lupa;
};

export const Settings: React.FC<Props> = ({
  svgObjects,
  handlerExport,
  activateCreatingPoint,
  actDeactAnimated,
  onChangeSvgSectionSwitch,
  onChangeSvgMaskSwitch,
  hideShowImage,
  initOpenState,
}) => {
  const [animated, setAnimated] = useState(false);
  const [imageShown, setImageShown] = useState(true);
  const [open, setOpen] = useState(initOpenState);
  const [lupa, setLupa] = useState<TpLupa>({ activated: false });
  const text = useLocal(LOCALS, "title");

  const onChangeAnimated = () => {
    setAnimated(!animated);
    actDeactAnimated();
  };

  useEffect(() => {
    if (!lupa.service) {
      setLupa((l: any) => ({ ...l, service: new Lupa("clippedLupa") }));
      return;
    }
    if (!lupa.activated) {
      lupa.service.turnOff();
      return;
    }
    if (lupa.activated) {
      lupa.service.turnOn();
      return;
    }
    return lupa.service.turnOff();
  }, [lupa]);

  const onLupa = () => {
    setLupa((e) => ({ ...e, activated: !e.activated }));
  };

  if (!open) {
    return (
      <div className="settings-trigger" onClick={() => setOpen(true)}>
        <Icon disabled name="setting" size="large" />
      </div>
    );
  }

  return (
    <>
      <div className="settings lyt_page2 on-page">
        <div className="header">
          <Title text={text} />
          {/* <h4 style={{ display: "flex", float: "right", marginTop: "-25px" }}>
        Animated: &nbsp;
        <CheckboxSemantic toggle checked={animated} onClick={onChangeAnimated} />
      </h4> */}
          <h4 style={{ display: "flex", float: "right", marginTop: "-25px" }}>
            Show image: &nbsp;
            <CheckboxSemantic
              toggle
              checked={imageShown}
              onClick={() => {
                setImageShown((e) => !e);
                hideShowImage();
              }}
            />
          </h4>
          <div className="close" onClick={() => setOpen(false)}>
            <Icon disabled name="close" size="large" />
          </div>
        </div>

        <h2 style={{ textAlign: "left" }}>
          {" "}
          <Icon disabled name="eye" size="large" /> Visibility:
        </h2>
        {
          <Visibility
            svgObjects={svgObjects}
            activateCreatingPoint={activateCreatingPoint}
            onChangeSvgSectionSwitch={onChangeSvgSectionSwitch}
            onChangeSvgMaskSwitch={onChangeSvgMaskSwitch}
          />
        }
        <br />
        <br />
        <div className="btns-container">
          <BtnLupa onClick={onLupa} />
          <BtnsDownload onClick={handlerExport} />
        </div>
      </div>
    </>
  );
};
