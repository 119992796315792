const shelf = [
  [15, 548.2857055664062],
  [25, 644.2857055664062],
  [32, 719.2857055664062],
  [346, 674.2857055664062],
  [467, 661.2857055664062],
  [460, 603.2857055664062],
  [334, 613.2857055664062],
  [39, 641.2857055664062],
  [29, 547.2857055664062],
].map(([px, py]) => ({ px, py }));

export default {
  shelf: {
    points: shelf,
    configs: {
      backgroundColor: "#7f4d2b",
      strokeWidth: "1",
      filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));",
    },
  },
};
