const desk = [
  [466, 712],
  [836, 890],
  [897, 888],
  [898, 770],
  [685, 689],
  [694, 627],
  [708, 624],
  [707, 615],
  [528, 553],
].map(([px, py]) => ({ px, py }));

export default {
  desk: {
    points: desk,
    configs: {
      backgroundColor: "#7f4d2b",
      strokeWidth: "1",
      filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));",
    },
  },
};
