import React, { useContext } from "react";
import { LikeBtn } from "./SVGS";
import "./styles.scss";
import { AppContext } from "AppContext";
import { usePostHog } from "posthog-js/react";

type Props = {
  story: string;
};

const FurtherActions: React.FC<Props> = ({ story }) => {
  const [state, setState] = useContext(AppContext) as any;
  const posthog = usePostHog();

  const handlerClickLike = () => {
    const newValue = !state.user?.lovesIt;
    setState({ ...state, user: { ...state.user, lovesIt: newValue } });
    posthog?.capture("clicked_love", { value: newValue, story: story });
  };

  // const handlerClickOpenChat = () => {
  //   setClicked((e) => ({ ...e, openChat: !e.openChat }));
  //   // function myFunction() {
  //   clickOpenChat();
  //   // setClicked((e) => ({ ...e, openChat: !e.openChat }));
  //   // }
  //   // const timeoutId = setTimeout(myFunction, 500);
  // };

  return (
    <div className="further-action">
      <p> {state.user?.lovesIt ? "Thanks!" : "Like"}</p>
      <LikeBtn className={state.user?.lovesIt ? "clicked" : ""} onClick={handlerClickLike} />
      {/* {showBtnOpenChat && (
        <svg
          className={"btn-back " + (clicked.openChat ? "clicked" : "")}
          onClick={handlerClickOpenChat}
          width="200px"
          height="100px"
          viewBox="0 0 500 100"
        >
          <rect className="outter" x="2" y="2" width="495" height="96" rx="10" ry="10"></rect>
          <rect className="inner" x="15" y="15" width="470" height="70" rx="8" ry="8"></rect>
          <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="16">
            {clicked.openChat ? "Close Chat" : "Open Chat"}
          </text>
        </svg>
      )} */}
    </div>
  );
};

export default FurtherActions;
