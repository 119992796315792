import React from "react";

type Props = {
  onClick: () => void;
};

export const Lupa: React.FC<Props> = ({ onClick }) => {
  return (
    <svg className="lupa-btn" width="33px" height="33px" onClick={onClick}>
      <g className="lupa lupa-btn">
        <path
          className="cls-1 btn"
          d="M22,23a1,1,0,0,1-.71-.29l-1.93-1.93a1,1,0,0,1,1.42-1.42l1.93,1.93a1,1,0,0,1,0,1.42A1,1,0,0,1,22,23Z"
        />
        <path
          className="cls-2 btn"
          d="M13,24A11,11,0,1,1,24,13,11,11,0,0,1,13,24ZM13,4a9,9,0,1,0,9,9A9,9,0,0,0,13,4Z"
        />
        <path
          className="cls-3 btn"
          d="M28.21,23.79l-3.5-3.5a1,1,0,0,0-1.42,0l-3,3a1,1,0,0,0,0,1.42l3.5,3.5a3.14,3.14,0,0,0,4.42,0A3.14,3.14,0,0,0,28.21,23.79Z"
        />
      </g>
    </svg>
  );
};
