const plant = [
  [522, 360.42857360839844],
  [518, 567.4285736083984],
  [560, 576.4285736083984],
  [592, 566.4285736083984],
  [611, 363.42857360839844],
  [568, 358.42857360839844],
].map(([px, py]) => ({ px, py }));

export default {
  plant: {
    points: plant,
    configs: { backgroundColor: "#7f4d2b", strokeWidth: "1", filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));" },
  },
};
