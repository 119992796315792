import * as THREE from "three";

import { loadingPoints } from "./data";

import * as Materials from "../materials";
import { LOGO_COLORS } from "../helpers";

// create a class called Words who has as interface the methods renderLoadingWord, the constructure receives the scene

class WordsImp {
  points: {
    [key: string]: THREE.Vector3[];
  };
  constructor(private scene: THREE.Scene) {
    this.points = loadingPoints;
  }

  renderLoadingWord = () => {
    // render all points
    let i = 0;

    const lettersGroup = new THREE.Group();
    for (const key in this.points) {
      // Create an array of LineCurve3 segments
      const lineCurves: THREE.LineCurve3[] = [];
      for (let i = 0; i < this.points[key].length - 1; i++) {
        const p0 = this.points[key][i];
        const p1 = this.points[key][i + 1];
        lineCurves.push(new THREE.LineCurve3(p0, p1));
      }

      // Use the array of LineCurve3 segments to create the path
      const path = new THREE.CurvePath();
      lineCurves.forEach((curve) => {
        path.add(curve);
      });

      // Create tube geometry along the path
      const tubeSegments = 350; // Number of segments
      const tubeRadius = 1; // Radius of the tube

      const tubeGeometry = new THREE.TubeGeometry(path as any, tubeSegments, tubeRadius);
      const tubeMesh = new THREE.Mesh(tubeGeometry, Materials.goldLogo(LOGO_COLORS.origin));

      tubeMesh.position.set(i * 15, 0, 0);
      lettersGroup.add(tubeMesh);
      i = i + 1;
    }

    lettersGroup.position.set(7, 0, 5);
    lettersGroup.rotateX(Math.PI);
    lettersGroup.rotateY(-Math.PI / 4);
    // lettersGroup.rotateZ(-Math.PI / 4);
    lettersGroup.scale.set(0.1, 0.1, 0.1);

    this.scene.add(lettersGroup);
  };
}

export default WordsImp;
