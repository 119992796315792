import * as d3 from "d3";
import { hpPointsToPath } from "services/BackgroundClient/helpers";
import * as Types from "./types";
import { FOTOS_DATA } from "./Data";

export const getWidth = (option: Types.Sizes) => {
  return option === "1024x1024" ? 1024 : 900;
};

export const getHeight = (option: Types.Sizes) => {
  return option === "1024x1024" ? 1024 : 1200;
};

export const viewPortSize = (option: Types.Sizes) => {
  return option === "1024x1024" ? "0 0 1024 1024" : "0 0 900 1200";
};

export const cromeBackground = (element: SVGElement) => {
  const thisSvg = d3.select(element);

  const pathToTakeOut = hpPointsToPath(FOTOS_DATA.desktop.objectsDefined.monitor.points);
  // " " +
  // hpPointsToPath(OBJECT_DEFINED[1].points) +
  // " " +
  // hpPointsToPath(OBJECT_DEFINED[2].points);
  // const pathToTakeOut = hpPointsToPath(OBJECT_DEFINED[0].points);
  thisSvg.append("path").attr("d", `M0 0 H900 V1200 H0 Z ${pathToTakeOut}`).attr("fill", "#04F404");
};

export const fillObjects = (element: SVGElement) => {
  const thisSvg = d3.select(element);

  const pathToTakeOut = hpPointsToPath(FOTOS_DATA.desktop.objectsDefined.monitor.points); //+ " " + hpPointsToPath(OBJECT_DEFINED[1].points);
  // const pathToTakeOut = hpPointsToPath(OBJECT_DEFINED[0].points);
  thisSvg.append("path").attr("d", pathToTakeOut).attr("fill", "#04F404");
};

export const setMouseMoveEvent = (element: HTMLElement, callback: (params: Types.Point) => void) => {
  const { left: px, top: py } = element.getBoundingClientRect();

  const handleMouseMove = (event: any) => {
    const { clientX: mouseAbsPosX, clientY: mouseAbsPosY } = event;
    callback({ px: mouseAbsPosX - px, py: mouseAbsPosY - py });
  };
  element.addEventListener("mousemove", handleMouseMove);
};

export const setClickEvent = (element: HTMLElement, callback: (params: Types.Point) => void) => {
  const { left: px, top: py } = element.getBoundingClientRect();

  const handleClick = (event: any) => {
    const { clientX: mouseAbsPosX, clientY: mouseAbsPosY } = event;
    callback({ px: mouseAbsPosX - px, py: mouseAbsPosY - py });
  };
  element.addEventListener("click", handleClick);
};
