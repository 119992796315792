// import Img from "assets/characters/mirando_welcome_reduced.png";
import Img from "assets/defined_fotos/profile.jpg"; // 2512 × 1616

import caraData from "./cara";
import bocaData from "./boca";
import camisaData from "./camisa";
import lentesData from "./lentes";
import ojosData from "./ojos";
import pizarraData from "./pizarra";
import monitorData from "./monitor";
import ipadData from "./ipad";
import deskData from "./desk";
import computer from "./computer";
import plantData from "./plant";
import shelfData from "./shelf";
/**
 * All of the bellow object were define in a viewBox="0 0 900 1200"
 * IMPORTANT: the point should be define in anti horario way. Otherways the substraction of the area will not work.
 */

type Point = { px: number; py: number };
type Transform = {
  scale?: number;
  translateX?: number;
  translateY?: number;
};

const transform: Transform = {
  scale: 0.74,
  translateX: 64,
  translateY: 159,
}; // because the image is 2512 × 1616

const applyTransform = (points: Point[], transform: Transform): Point[] => {
  const { scale = 1, translateX = 0, translateY = 0 } = transform;

  return points.map(({ px, py }) => {
    const newX = px * scale + translateX;
    const newY = py * scale + translateY;
    return { px: newX, py: newY };
  });
};

const TITLE_CONFIG = {
  paddingLeft: 30,
  paddingTop: 10,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 62,
      color: "#f5c51f",
      heightLine: 80, // 80, //40
    },
    p: {
      characterPerLine: 25,
      fontSize: 32,
      color: "white",
      heightLine: 62,
    },
  },
  backgroundColor: "#311408",
  // p1: TITULO[0], // Top left corner
  // p2: TITULO[1], // Bottom left corner
  // p3: TITULO[TITULO.length - 2], // Top right corner
  // p4: TITULO[TITULO.length - 3], // Bottom right corner
};

const OBJECTS_DEFINED = {
  ...caraData,
  ...camisaData,
  ...ojosData,
  ...lentesData,
};

// const {
//   monitorMain,
//   monitorTabGoogle,
//   monitorTabMiro,
//   monitorTabNotion,
//   monitor,
//   title,
//   overComputer,
//   titleWelcome,
// } = OBJECTS_DEFINED;

const transformData = (data: any) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    acc[key as any] = {
      ...(value as any),
      points: applyTransform((value as any).points, transform),
    };
    return acc;
  }, {} as any);
};

console.log("camisaData", camisaData, transformData(camisaData));

const DATA = {
  image: Img,
  textElements: {},
  objectsDefined: OBJECTS_DEFINED,
  svgObjects: {
    plant: {
      ...plantData,
    },
    shelf: {
      ...shelfData,
    },
    pizarra: {
      ...pizarraData,
    },
    desk: {
      ...deskData,
    },
    monitor: {
      ...monitorData,
    },
    ipad: {
      ...ipadData,
    },
    computer: {
      ...computer,
    },
    camisa: {
      ...transformData(camisaData),
    },
    cara: {
      ...transformData(caraData),
    },
    boca: {
      ...transformData(bocaData),
    },
    lentes: {
      ...transformData(lentesData),
    },
    ojos: {
      ...transformData(ojosData),
    },
  },
};

export default DATA;
