import "./styles.scss";
import React, { useState, useRef, useEffect, MouseEvent, MouseEventHandler } from "react";
import * as Types from "./types";
import * as Helpers from "./helpers";
import * as htmlToImage from "html-to-image";
import { FOTOS_DATA } from "../../../components/FotoCatalog/Data";
import * as d3 from "d3";

import { Settings } from "./Settings";

const CONFIG: Types.CONFIG_OPTIONS = {
  imageSize: "portfollio",
  color: "#04F404", // Chroma
  fillObjects: true,
  fillPoints: true,
  defineNewElementMode: false,
  showSettings: false,
  asComicColors: false,
};

// const { objectsDefined, image, imageComic, imageObjects, svgObjects } = FOTOS_DATA.books;
// const { objectsDefined, image, svgObjects } = FOTOS_DATA.desktop;
const { objectsDefined, image, svgObjects } = FOTOS_DATA.ignacioFront;

const svgData = Object.entries(svgObjects).map(([key, value]) => ({
  key,
  svgData: Object.entries(value).map(([key, value]) => ({ key, data: value, visible: true })),
  visible: true,
}));

const objectsDefinedData = objectsDefined;

const EditImage: React.FC = () => {
  const [configs, setConfigs] = useState<Types.CONFIG_OPTIONS>(CONFIG);
  const divRef = useRef<HTMLDivElement>(null);
  const [objectsDefined, setObjectsDefined] = useState(objectsDefinedData);
  const [svgsDefined, setSvgsDefine] = useState<Types.SvgObjects>(svgData as any);

  const [points, setPoints] = useState<Types.Point[]>([]);
  const [cursorPosition, setCursorPosition] = useState<Types.Point>({ px: 0, py: 0 });
  const [showImage, setShowImage] = useState(true);

  const [editingMode, setEditingMode] = useState<Types.EditingMode>({
    active: false,
    point: { px: 0, py: 0 },
  });

  useEffect(() => {
    const newPointsRaw = points.map(({ px, py }) => [px, py]);

    navigator.clipboard
      .writeText(JSON.stringify(newPointsRaw))
      .then(() => {
        console.log("Text copied to clipboard successfully!");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard: ", err);
      });
  }, [points]);

  useEffect(() => {
    const { active, point, objectKey = "" } = editingMode;
    if (!active) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      event.preventDefault();

      switch (event.key) {
        case "ArrowUp": {
          const newPoint = { px: point.px, py: point.py - 1 };
          updatePoint(point, newPoint, objectKey);
          setEditingMode((e) => ({ ...e, point: newPoint }));
          break;
        }
        case "ArrowLeft": {
          const newPoint = { px: point.px - 1, py: point.py };
          updatePoint(point, newPoint, objectKey);
          setEditingMode((e) => ({ ...e, point: newPoint }));
          break;
        }
        case "ArrowDown": {
          const newPoint = { px: point.px, py: point.py + 1 };
          updatePoint(point, newPoint, objectKey);
          setEditingMode((e) => ({ ...e, point: newPoint }));
          break;
        }
        case "ArrowRight": {
          const newPoint = { px: point.px + 1, py: point.py };
          updatePoint(point, newPoint, objectKey);
          setEditingMode((e) => ({ ...e, point: newPoint }));
          break;
        }
        case "n": {
          console.log("Quiero un nuevo punto");
          const newPoint = addNewPoint(point, objectKey);
          setEditingMode((e) => ({ ...e, point: newPoint }));
          break;
        }
        case "Enter": {
          setEditingMode((e) => ({ ...e, active: false }));
          break;
        }
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editingMode]);

  const addClips = () => {
    const svgElement = d3.select(`#SVG-background`);

    // const titaniumPath =
    //   "M0,168 244,119 249,124 259,121 267,128 269,139 252,146 296,467 313,466 315,481 297,485 304,531 32,555 25,512 21,513 18,497 23,496 17,470 0,472z"; //d3.select(`polyline.background`).attr("d");

    // const defs = svgElement.append("defs");
    // defs.append("clipPath").attr("id", "ClippedTitanium").append("path").attr("d", titaniumPath);

    const pupilaInGroupLeft = svgElement.selectAll(`polyline.clipped-titanium`);
    pupilaInGroupLeft.attr("clip-path", "url(#clippedTitanium)");
    // pupilaInGroupLeft.attr("mask", "url(#clippedTitanium)");
    console.log("addClips -> pupilaInGroupLeft", pupilaInGroupLeft);
  };

  const updatePoint = (point: Types.Point, newPoint: Types.Point, objectKey: string) => {
    console.log("updatePoint -> point", point, objectKey);

    const findAndModifyPoint = (points: Types.Point[], targetPoint: Types.Point, newValue: Types.Point) => {
      const index = points.findIndex((point) => point.px === targetPoint.px && point.py === targetPoint.py);
      if (index !== -1) {
        points[index] = newValue;

        const newPointsRaw = points.map(({ px, py }) => [px, py]);
        console.log("To clipboard -> newPointsRaw: ", newPointsRaw);
        navigator.clipboard.writeText(JSON.stringify(newPointsRaw));

        return points;
      } else {
        return points;
      }
    };

    let newObject = {} as any;
    Object.entries(objectsDefined).map(([key, value]) => {
      const modifiedPoints = findAndModifyPoint(value.points, point, newPoint);
      newObject[key] = { ...value, points: modifiedPoints };
    });

    setObjectsDefined(newObject);
  };

  const addNewPoint = (subPoint: Types.Point, objectKey: string) => {
    console.log("newPoint -> point", subPoint, objectKey);

    const currentPoints = (objectsDefined as any)[objectKey].points;

    const indexSubPoint = currentPoints.findIndex(
      (point: { px: number; py: number }) => point.px === subPoint.px && point.py === subPoint.py
    );

    let newValue = { px: 0, py: 0 };
    if (indexSubPoint !== -1) {
      const nextPoint = currentPoints[indexSubPoint + 1];

      const { px, py } = nextPoint;

      newValue = {
        px: subPoint.px + (px - subPoint.px) / 2,
        py: subPoint.py + (py - subPoint.py) / 2,
      } as Types.Point;

      currentPoints.splice(indexSubPoint + 1, 0, newValue);
    }

    setObjectsDefined(objectsDefined);

    return newValue;
  };

  const handleExport = () => {
    if (divRef.current) {
      htmlToImage
        // .toSvg(divRef.current)
        .toPng(divRef.current)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "export.png";
          link.click();
        })
        .catch((error) => {
          console.error("Export failed:", error);
        });
    }
  };

  const activateCreatingPoint = () => {
    setConfigs({ ...configs, defineNewElementMode: true });
  };

  const _onClick = () => {
    addClips();

    const scrollY = window.scrollY;
    console.log("editingMode", editingMode);
    !editingMode.active && setPoints((pts) => [...pts, { px: cursorPosition.px, py: cursorPosition.py + scrollY }]);
  };

  const _handleMouseMove = (e: MouseEvent<SVGSVGElement>) => {
    const { clientX: mouseAbsPosX, clientY: mouseAbsPosY } = e;
    setCursorPosition({ px: mouseAbsPosX, py: mouseAbsPosY });
  };

  const _onClickPointHandler = (
    event: React.MouseEvent<SVGCircleElement, MouseEvent>,
    point: Types.Point,
    objectKey: string
  ) => {
    console.log("EditImage:React.FC -> point", point);
    const editingMode = {
      activate: () => {
        const clickedElement = event.target as HTMLDivElement;
        const className = [clickedElement.attributes[0].value, "editing"].join(" ");
        clickedElement.attributes[0].value = className;
        console.log("{ active: true, point: point, htmlEle: clickedElement, objectKey }", {
          active: true,
          point: point,
          htmlEle: clickedElement,
          objectKey,
        });

        setEditingMode({ active: true, point: point, htmlEle: clickedElement, objectKey });
      },
    };

    editingMode.activate();
  };

  const onChangeSvgSectionSwitch = (keyTarget: string) => {
    const newSvgObjectsShown = svgsDefined.map((e) => {
      if (e.key === keyTarget) {
        return { ...e, visible: !e.visible, svgData: e.svgData.map((ee) => ({ ...ee, visible: !e.visible })) };
      } else {
        return e;
      }
    });

    setSvgsDefine(newSvgObjectsShown);
  };

  const onChangeSvgMaskSwitch = (sectionTarget: string, keyTarget: string) => {
    console.log("onChangeSvgMaskSwitch -> sectionTarget, ", sectionTarget, ", keyTarget: ", keyTarget);
    const newSvgObjectsShown = svgsDefined.map((e) => {
      if (e.key === sectionTarget) {
        return {
          ...e,
          // visible: !e.visible,
          svgData: e.svgData.map((ee) => {
            if (ee.key === keyTarget) {
              return { ...ee, visible: !ee.visible };
            } else {
              return ee;
            }
          }),
        };
      } else {
        return e;
      }
    });

    setSvgsDefine(newSvgObjectsShown);
  };

  const _hideShowImage = () => {
    setShowImage((e) => !e);
  };

  const clipObject = (objectKey: keyof typeof objectsDefined) => {
    const svgElement = d3.select(`#SVG-background`);
    const object = objectsDefined[objectKey];
    const { points } = object;

    const path = points.reduce((acc, point) => `${acc} ${point.px},${point.py}`, "");

    const defs = svgElement.append("defs");
    defs
      .append("clipPath")
      .attr("id", `clipped-${objectKey}`)
      .append("path")
      .attr("d", "M" + path + "z");

    const image = svgElement.select("#idImg");
    image.attr("clip-path", `url(#clipped-${objectKey})`);
  };

  // const PATH_TO_SUBSTRACT = "M20,20 L40,20 L40,40 L20,40 Z M60,60 L80,60 L80,80 L60,80 Z M10,10 L90,10 L90,90 L10,90 Z";
  // const PATH_TO_SUBSTRACT =
  //   "M461,204 573,180 574,182 587,381 473,395 461,204z M731,134 761,127 793,120 810,115 834,107 858,95 859,96 861,107 863,134 865,176 863,250 860,336 855,337 844,340 831,343 804,348 760,357 719,365 714,138z M596,193 598,192 708,170 711,173 711,176 715,236 717,255 716,273 714,299 715,306 714,317 713,346 713,360 707,361 693,364 672,368 607,376 599,377 595,194 596,193z M 0,0 0,1200 900,1200 900,0z";
  const PATH_TO_SUBSTRACT =
    "M514,448 789,411 814,762 552,773z M0,168 244,119 249,124 259,121 267,128 269,139 252,146 296,467 313,466 315,481 297,485 304,531 32,555 25,512 21,513 18,497 23,496 17,470 0,472z M875,364 472,411 387,411 359,411 340,224 420,199 424,204 433,199 447,203 452,208 464,203 573,180 578,186 601,180 603,190 708,169 714,173 714,138 793,120 834,107 858,95 863,100 868,105 880,99 886,130z M401,584 461,581 473,589 472,741 457,743 447,759 57,766 52,751 49,752 59,596 158,590 172,603 254,593 274,593 278,589 366,582 375,614 377,588 399,584z M 388,839 421,1157 416,1158 416,1167 171,1139 120,837 125,832 128,827 388,838z M 900,1112 439,1073 436,1055 439,1047 447,1048 449,1036 459,891 548,901 558,903 569,907 569,866 570,859 590,855 696,863 703,874 715,877 804,879 841,880 862,882 872,883 882,888 884,894 900,1044 900,1048z M 370,420 385,550 391,549 398,553 493,546 480,410z M 0,0 0,1200 900,1200 900,0z";

  return (
    <>
      <div ref={divRef} className="wrapper-svg">
        <svg
          onMouseMove={_handleMouseMove}
          onClick={_onClick}
          className="editing_image__svg"
          id="SVG-background"
          width="900"
          viewBox={"0 0 900 1200"}
          preserveAspectRatio="none"
        >
          <defs>
            <clipPath id="clippedTitanium">
              <path d={PATH_TO_SUBSTRACT} fill="rgb(245 245 245 / 68%)" fill-rule="evenodd" />
            </clipPath>
            <clipPath id="clippedMidLevel">
              <path d={PATH_TO_SUBSTRACT} fill="rgb(245 245 245 / 68%)" fill-rule="evenodd" />
            </clipPath>
            <clipPath id="clippedTopLevel">
              <path d={PATH_TO_SUBSTRACT} fill="rgb(245 245 245 / 68%)" fill-rule="evenodd" />
            </clipPath>
            <clipPath id="clippedBottomLevel">
              <path d={PATH_TO_SUBSTRACT} fill="rgb(245 245 245 / 68%)" fill-rule="evenodd" />
            </clipPath>
            <clipPath id="clippedValparaiso">
              <path d={PATH_TO_SUBSTRACT} fill="rgb(245 245 245 / 68%)" fill-rule="evenodd" />
            </clipPath>
            <clipPath id="clippedMax">
              <path d={PATH_TO_SUBSTRACT} fill="rgb(245 245 245 / 68%)" fill-rule="evenodd" />
            </clipPath>
            <clipPath id="clippedVinozero">
              <path d={PATH_TO_SUBSTRACT} fill="rgb(245 245 245 / 68%)" fill-rule="evenodd" />
            </clipPath>
            <clipPath id="clippedLips">
              <path
                d={
                  "M444,645 471,663 489,667 496,669 500,670 507,671 517,669 525,668 537,668 543,666 575,647 575,647 543,659 526,661 518,660 512,662 507,661 494,659 488,657 478,656 471,655 444,645z M444,645 471,655 478,656 488,657 494,659 507,661 512,662 518,660 526,661 543,659 575,647 537,651 521,651 511.5,651.5 501,650 492,649 481,648 468,649z"
                }
                fill="rgb(245 245 245 / 68%)"
                fill-rule="evenodd"
              />
            </clipPath>
            <clipPath id="clippedLipsSmiling">
              <path
                d={
                  "M582,577.75 582,581.75 588,586.75 598,594.75 608,598.75 615,600.75 624,601.75 635,601.75 644,600.75 654,596.75 664,592.75 669,589.75 679,581.75 681,581.75 681,579.75 681,576.75 677,578.75 671,577.75 667,577.75 663,578.75 655,578.75 649,578.75 641,577.75 637,577.75 630,578.75 624,578.75 617,578.75 612,578.75 604,578.75 598,578.75 594,578.75 590,578.75 587,577.75 585,576.75 583,576.75z"
                }
                fill="rgb(245 245 245 / 68%)"
                fill-rule="evenodd"
              />
            </clipPath>

            <clipPath id="clippedLupa">
              <circle cx="200" cy="200" r="100" />
            </clipPath>
          </defs>

          <image
            href={image}
            id="idImg"
            style={{ height: "100%", opacity: showImage ? 1 : 0, width: "100%" }}
            // clip-path="url(#clippedLupa)"
          />

          <g className="elements-defined">
            {svgsDefined.map(({ key, svgData, visible }) => {
              if (!visible) return [];
              return (
                <g className={"g-" + key}>
                  {svgData.map(({ key, visible, data }) => {
                    if (!visible) return [];
                    const chromaColor = CONFIG.color;
                    const { points, configs } = data;

                    const { filter, strokeWidth, stroke, backgroundColor } = configs;
                    const comicColor = backgroundColor || chromaColor;
                    const className = Helpers.camelToSnake(key) + " " + configs?.class;

                    return (
                      <>
                        {CONFIG.fillObjects && (
                          <polyline
                            className={className}
                            points={points.reduce((acc, point) => `${acc} ${point.px},${point.py}`, "")}
                            fill={CONFIG.asComicColors ? comicColor : "rgba(4,244,4,0.3)"}
                            strokeWidth={strokeWidth || "1"}
                            stroke={stroke || "rgba(0,0,0,0.3"}
                            filter={filter || "none"}
                          />
                        )}

                        {CONFIG.fillPoints &&
                          points.map((p, index) => {
                            const className = [
                              "point",
                              p.px === editingMode.point.px && p.py === editingMode.point.py ? "selected" : "",
                              editingMode.active ? "mode-editing-on" : "",
                            ].join(" ");
                            return (
                              <circle
                                className={className}
                                key={"point-" + index + "-" + p.px + "-" + p.py}
                                onClick={(event) => _onClickPointHandler(event as any, p, key)}
                                r="1px"
                                cx={p.px}
                                cy={p.py}
                                fill="white"
                                strokeWidth="1"
                                stroke={"red"}
                              />
                            );
                          })}
                      </>
                    );
                  })}
                </g>
              );
            })}
          </g>

          {/* <g className="g-dientes clipped-lips">
            <polyline
              className="dientes"
              points=" 594,581 594,586 598,590 600,590 602,592 605,591 607,591 610,591 612,590 613,589 613,592 616,592 621,593 625,592 630,593 637,592 638,591 638,590 643,592 645,592 647,591 649,589 653,594 655,593 657,589 657,588 661,591 664,590 665,591 667,589 667,588 667,587 670,587 670,580 668,580 666,580 664,581 663,580 661,579 659,580 658,581 657,579 655,579 652,579 651,579 649,582 649,580 646,579 644,578 642,579 640,581 639,584 637,580 637,579 635,579 632,579 629,580 627,581 626,583 625,585 624,583 623,581 623,580 622,580 614,579 613,583 613,585 611,580 610,579 608,579 605,580 605,584 604,586 603,581 601,580 600,580 596,580 594,581"
              fill="rgb(241 241 241)"
              style={{ transform: "scale(1.3) translate(-240px, -82px)" }}
            ></polyline>
          </g> */}

          {/* <g className="">
            <g className="g-labiosExteriores">
              <polyline
                className="labios-exteriores undefined"
                points=" 585,583.25 589,592.25 593,600.25 602,606.25 607,610.25 612,611.25 618,612.25 637,613.25 642,613.25 648,611.25 664,601.25 673,590.25 678,583.25 669,576.25 662,574.25 654,572.25 647,572.25 637,571.25 634,572.25 629,572.25 623,574.25 617,574.25 610,573.25 605,572.25 599,572.25 593,573.25 590,574.25"
                fill="#c87578"
              ></polyline>
            </g>
            <g className="g-backgroundBoca">
              <polyline
                className="background-boca undefined"
                points=" 582,577.75 582,581.75 588,586.75 598,594.75 608,598.75 615,600.75 624,601.75 635,601.75 644,600.75 654,596.75 664,592.75 669,589.75 679,581.75 681,581.75 681,579.75 681,576.75 677,578.75 671,577.75 667,577.75 663,578.75 655,578.75 649,578.75 641,577.75 637,577.75 630,578.75 624,578.75 617,578.75 612,578.75 604,578.75 598,578.75 594,578.75 590,578.75 587,577.75 585,576.75 583,576.75"
                fill="#300000"
              ></polyline>
            </g>
            <g className="g-encias clipped-lips-smiling">
              <polyline
                className="encias undefined"
                points=" 593,576 595,584 667,585 669,582 669,577"
                fill="#ba5a58"
              ></polyline>
            </g>
            <g className="g-lengua" fill="blueviolet">
              <polyline
                className="lengua undefined"
                points=" 601,587.25 601,595.25 609,601.25 649,600.25 653,598.25 656,588.25"
                fill="#632417"
              ></polyline>
            </g>
            <g className="g-dientesTop clipped-lips-smiling">
              <polyline
                className="dientes-top undefined"
                points=" 594,581 594,586 598,590 600,590 602,592 605,591 607,591 610,591 612,590 613,589 613,592 616,592 621,593 625,592 630,593 637,592 638,591 638,590 643,592 645,592 647,591 649,589 653,594 655,593 657,589 657,588 661,591 664,590 665,591 667,589 667,588 667,587 670,587 670,580 668,580 666,580 664,581 663,580 661,579 659,580 658,581 657,579 655,579 652,579 651,579 649,582 649,580 646,579 644,578 642,579 640,581 639,584 637,580 637,579 635,579 632,579 629,580 627,581 626,583 625,585 624,583 623,581 623,580 622,580 614,579 613,583 613,585 611,580 610,579 608,579 605,580 605,584 604,586 603,581 601,580 600,580 596,580 594,581"
                fill="#ffece3"
              ></polyline>
            </g>
            <g className="g-dientesBottom clipped-lips-smiling">
              <polyline
                className="dientes-bottom undefined"
                points=" 604,597 604,601 608,602 617,605 626,606 654,605 653,599 648,600 645,595 642,597 635,595 633,597 626,595 618,594 611,594 610,596 610,594 607,594"
                fill="rgb(241 241 241)"
                style={{ fill: "#ffece3" }}
              ></polyline>
            </g>
            <g className="g-dientes clipped-lips">
              <polyline
                className="dientes"
                points=" 594,581 594,586 598,590 600,590 602,592 605,591 607,591 610,591 612,590 613,589 613,592 616,592 621,593 625,592 630,593 637,592 638,591 638,590 643,592 645,592 647,591 649,589 653,594 655,593 657,589 657,588 661,591 664,590 665,591 667,589 667,588 667,587 670,587 670,580 668,580 666,580 664,581 663,580 661,579 659,580 658,581 657,579 655,579 652,579 651,579 649,582 649,580 646,579 644,578 642,579 640,581 639,584 637,580 637,579 635,579 632,579 629,580 627,581 626,583 625,585 624,583 623,581 623,580 622,580 614,579 613,583 613,585 611,580 610,579 608,579 605,580 605,584 604,586 603,581 601,580 600,580 596,580 594,581"
                fill="rgb(241 241 241)"
              ></polyline>
            </g>
          </g> */}

          <g className="lupa lupa-cursor">
            <path
              className="cls-1"
              d="M22,23a1,1,0,0,1-.71-.29l-1.93-1.93a1,1,0,0,1,1.42-1.42l1.93,1.93a1,1,0,0,1,0,1.42A1,1,0,0,1,22,23Z"
            />
            <path
              className="cls-2"
              d="M13,24A11,11,0,1,1,24,13,11,11,0,0,1,13,24ZM13,4a9,9,0,1,0,9,9A9,9,0,0,0,13,4Z"
            />
            <path
              className="cls-3"
              d="M28.21,23.79l-3.5-3.5a1,1,0,0,0-1.42,0l-3,3a1,1,0,0,0,0,1.42l3.5,3.5a3.14,3.14,0,0,0,4.42,0A3.14,3.14,0,0,0,28.21,23.79Z"
            />
          </g>

          {configs.defineNewElementMode && (
            <polyline
              points={points.reduce((acc, point) => `${acc} ${point.px},${point.py}`, "")}
              fill={"rgba(4,244,4,0.4"}
              strokeWidth="1"
              stroke={configs.color}
            />
          )}

          {/* <image
            // href={imageObjects}
            href={image}
            id="idImg"
            style={{ height: "100%", opacity: showImage ? 1 : 0, width: "100%" }}
            // clip-path="url(#clippedLupa)"
          /> */}
        </svg>
      </div>
      <Settings
        svgObjects={svgsDefined}
        handlerExport={handleExport}
        activateCreatingPoint={activateCreatingPoint}
        onChangeSvgSectionSwitch={onChangeSvgSectionSwitch}
        onChangeSvgMaskSwitch={onChangeSvgMaskSwitch}
        actDeactAnimated={() => {}}
        hideShowImage={_hideShowImage}
        initOpenState={configs.showSettings}
      />
      {/* <button onClick={() => clipObject("ipad") as MouseEventHandler<HTMLButtonElement> | undefined}>Clip Wall</button> */}
    </>
  );
};

export default EditImage;
