const computer = [
  [596, 628],
  [587, 694],
  [690, 730],
  [700, 658],
].map(([px, py]) => ({ px, py }));

export default {
  computer: {
    points: computer,
    configs: {
      backgroundColor: "black",
      strokeWidth: "1",
      filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));",
    },
  },
};
