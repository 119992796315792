import React, { useState } from "react";
import { Checkbox as CheckboxSemantic, Label, Button, Icon } from "semantic-ui-react";
import NewElement from "./NewElement";
import * as Types from "../../types";

import "./styles.scss";

type Props = {
  svgObjects: Types.SvgObjects;
  activateCreatingPoint: () => void;
  onChangeSvgSectionSwitch: (keyTarget: string) => void;
  onChangeSvgMaskSwitch: (sectionTarget: string, keyTarget: string) => void;
};

export const Visibility: React.FC<Props> = ({
  svgObjects,
  activateCreatingPoint,
  onChangeSvgSectionSwitch,
  onChangeSvgMaskSwitch,
}) => {
  const [isCreating, setIsCreating] = useState(false);

  const _handlerAddClick = () => {
    setIsCreating(true);
    activateCreatingPoint();
  };

  const renderSVGLayers = (svgLayersData: Types.SvgObjects[number]["svgData"], sectionKey: string) => {
    return (
      <div className="svg__section__layers">
        {svgLayersData.map(({ visible, key }) => {
          const className = "id-" + key;

          return (
            <div key={key} className={"layer " + key}>
              <CheckboxSemantic
                className={className}
                checked={visible}
                onChange={() => onChangeSvgMaskSwitch(sectionKey, key)}
              />
              <Label className={className} as="a" size="mini" onClick={() => onChangeSvgMaskSwitch(sectionKey, key)}>
                {key}
              </Label>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSVGSection = (svg: Types.SvgObjects[number]) => {
    const { visible, key, svgData } = svg;

    const className = "id-" + key;
    return (
      <div className="svg__section">
        <div key={key} className="switch">
          {/* <CheckboxSemantic
            className={className}
            slider
            checked={visible}
            onChange={() => onChangeSvgSectionSwitch(key)}
          /> */}
          <div onClick={() => onChangeSvgSectionSwitch(key)}>
            <Icon className="pointer" disabled name={visible ? "eye" : "hide"} size="large" />
          </div>
          <Label className={className} as="a" tag onClick={() => onChangeSvgSectionSwitch(key)} size="mini">
            {key}
          </Label>
        </div>
        {renderSVGLayers(svgData, key)}
      </div>
    );
  };

  return (
    <div className="visibility-section">
      <h3 style={{ textAlign: "left" }}>
        {/* <CheckboxSemantic
          toggle
          checked={categoriesVisibilityStatus.ai}
          onClick={(event) => _onClickVisibleCategory(event, "ai")}
        /> */}
        {/* <span className="icon">🤖 </span> */}
        SVG objects defined:
      </h3>
      {svgObjects.map((svg) => {
        return renderSVGSection(svg);
      })}

      <br />
      <hr />

      {!isCreating && (
        <Button icon onClick={_handlerAddClick} color="instagram">
          <Icon name="add" />
        </Button>
      )}

      {isCreating && <NewElement />}
    </div>
  );
};
