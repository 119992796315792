const ipadScreen = [
  [41, 626],
  [47, 710],
  [168, 695],
  [158, 618],
].map(([px, py]) => ({ px, py }));

export default {
  ipadScreen: {
    points: ipadScreen,
    configs: {
      backgroundColor: "whitesmoke",
      strokeWidth: "1",
      filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));",
    },
  },
};
