import React from "react";
import { Dropdown } from "semantic-ui-react";
import "./style.scss";
import { Language } from "../types";
import LOCALS from "./locals.json";
import { usePostHog } from "posthog-js/react";
import { useLanguage } from "..";

const LANGUAGES_FLAGS: Record<Language, JSX.Element> = {
  en: (
    <img
      width="32"
      alt="Flag-map of the United States (50 states)"
      src="https://upload.wikimedia.org/wikipedia/commons/e/e0/United_States_flag_waving_icon.svg" // USA Detailed
    />
  ),
  es: (
    <img
      width="32"
      alt="Flag map of Spain"
      src="https://upload.wikimedia.org/wikipedia/commons/0/0c/Spain_flag_waving_icon.svg"
    />
  ),
  de: (
    <img
      width="32"
      alt="Flag map of Germany"
      src="https://upload.wikimedia.org/wikipedia/commons/a/a2/Germany_flag_waving_icon.svg"
    />
  ),
};

const getLanguageOptions = (language: Language) =>
  Object.keys(LANGUAGES_FLAGS).map((key) => ({ value: key, text: LOCALS[language][key as Language] }));

const LanguagesDropdown = React.memo(() => {
  const { language, setLanguage } = useLanguage();
  const posthog = usePostHog();
  const trigger = language && LANGUAGES_FLAGS[language];

  const _onChange = (e: any, { value }: { value: Language }) => {
    posthog?.capture("change_language", { to: value, from: language });
    setLanguage(value);
  };

  return (
    <div className="language-selector">
      <Dropdown
        direction="left"
        onChange={_onChange as any}
        trigger={trigger}
        options={getLanguageOptions(language as any)}
        defaultValue={language}
      />
    </div>
  );
});

export default LanguagesDropdown;
