import * as THREE from "three";

const lPoints = [
  [0.0, -17.32], // Start point
  [-7.5, 4.33], // Second point
  [7.5, 4.33], // Third point
];

const oPoints = [
  [0.0, -6.5], // Start point
  [-5.63, -3.25], // Second point
  [-5.63, 3.25], // Third point
  [0.0, 6.5], // Fourth point
  [5.63, 3.25], // Fifth point
  [5.63, -3.25], // Sixth point
  [0.0, -6.5], // Seventh point
];

const aPoints = [
  [0.0, 7.79], // First point
  [-3.37, 1.95], // Second point
  [-6.75, -3.9], // Third point
  [0.0, -3.9], // Fourth point
  [-3.37, 1.95], // Fifth point
  [0.0, -3.9], // Sixth point
  [6.75, -3.9], // Seventh point
];

const dPoints = [
  [-7.5, -4.33], // First point
  [7.5, 4.33], // Second point
  [0.0, -8.66], // Third point
  [-7.5, -4.33], // Fourth point
];

const iPoints = [
  [0.0, 8.66], // First point
  [0.0, 0.0], // Second point
];

const nPoints = [
  [-7.5, 4.33], // First point
  [-7.5, -4.33], // Second point
  [7.5, 4.33], // Third point
  [7.5, -4.33], // Fourth point
];

const gPoints = [
  [0.0, -6.5], // First point
  [-11.25, -6.5], // Second point
  [-5.63, 3.25], // Third point
  [0.0, 0.0], // Fourth point
  [0.0, -6.5], // Fifth point
  [0.0, 0.0], // Sixth point
  [0.0, 12.99], // Seventh point
  [-11.25, 6.5], // Eighth point
];

const dotPoints = [
  [0.0, -1.73], // First point
  [-1.5, -0.87], // Second point
  [-1.5, 0.87], // Third point
  [0.0, 1.73], // Fourth point
  [1.5, 0.87], // Fifth point
  [1.5, -0.87], // Sixth point
  [0.0, -1.73], // Seventh point
];

export const loadingPoints: {
  [key: string]: THREE.Vector3[];
} = {
  l: lPoints.map((point) => new THREE.Vector3(point[0], point[1], 0)),
  o: oPoints.map((point) => new THREE.Vector3(point[0], point[1], 0)),
  a: aPoints.map((point) => new THREE.Vector3(point[0], point[1], 0)),
  d: dPoints.map((point) => new THREE.Vector3(point[0], point[1], 0)),
  i: iPoints.map((point) => new THREE.Vector3(point[0], point[1], 0)),
  n: nPoints.map((point) => new THREE.Vector3(point[0], point[1], 0)),
  g: gPoints.map((point) => new THREE.Vector3(point[0], point[1], 0)),
  dot: dotPoints.map((point) => new THREE.Vector3(point[0], point[1], 0)),
};
