import React, { useEffect, useState } from "react";
import "./styles.scss";
import { ArrowUnterTitle } from "./animations";
import * as d3 from "d3";
import * as Types from "./types";
import { callbackify } from "util";

export const Title: React.FC<Types.TitleProps> = ({ text, textAlign = "left" }) => {
  const wrapperD3 = React.createRef() as React.RefObject<HTMLInputElement>;

  useEffect(() => {
    const wrapper = wrapperD3.current;
    if (wrapper) {
      const h1HeightLine = 46;
      const arrowAnimation = new ArrowUnterTitle(wrapper, { x: 0, y: h1HeightLine });
      arrowAnimation.animate();
    }
  }, []);

  return (
    <div className={`text__title ${`ta-${textAlign}`}`}>
      <h1 ref={wrapperD3}>{text}</h1>
    </div>
  );
};

export const TitleMAIN: React.FC<Types.TitleProps> = ({ text, textAlign = "left" }) => (
  <div className={`text__title main ${`ta-${textAlign}`}`}>
    <h1>{text}</h1>
  </div>
);

export const TitleSVG: React.FC<Types.TitleProps> = ({ text, textAlign = "left" }) => {
  const wrapperD3 = React.createRef() as React.RefObject<HTMLInputElement>;

  useEffect(() => {
    const element = wrapperD3.current;
    const svg = d3.select(element);
    svg.append("text").text(text);
  }, []);

  return <div ref={wrapperD3} className={`text-svg title ${`ta-${textAlign}`}`}></div>;
};

export const SubTitleSVG: React.FC<Types.TitleProps> = ({ text, textAlign = "left" }) => {
  const wrapperD3 = React.createRef() as React.RefObject<HTMLInputElement>;

  useEffect(() => {
    const element = wrapperD3.current;
    const svg = d3.select(element);
    svg.append("text").text(text);
  }, []);

  return <div ref={wrapperD3} className={`text-svg title ${`ta-${textAlign}`}`}></div>;
};

export const TextSVG: React.FC<Types.TextSVGProps> = ({ text, textAlign = "left", renderValue = 1 }) => {
  const [maxHeightWhiteLayer, setMaxHeightWhiteLayer] = useState(0);
  const wrapperD3 = React.createRef() as React.RefObject<HTMLInputElement>;
  const whiteLayerRef = React.createRef() as React.RefObject<HTMLInputElement>;

  useEffect(() => {
    const element = wrapperD3.current;
    const svg = d3.select(element);

    text.flat().forEach((e: string) => {
      if (e === "") {
        svg.append("br");
      } else {
        svg.append("text").text(e);
      }
    });

    if (element) {
      const textHeight = element.offsetHeight;
      setMaxHeightWhiteLayer(textHeight);
    }
  }, []);

  return (
    <div ref={wrapperD3} className={`text-svg text ${`ta-${textAlign}`}`}>
      <div
        ref={whiteLayerRef}
        className="text_layer-white"
        style={{ height: maxHeightWhiteLayer * (1 - renderValue) }}
      ></div>
    </div>
  );
};
