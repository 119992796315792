import * as THREE from "three";

import Textures from "../textures";

export const wireframe = new THREE.MeshStandardMaterial({
  // color: new THREE.Color(0x8b4513),
  color: "black",
  side: THREE.DoubleSide,
  wireframe: true,
  wireframeLinewidth: 2,
});

export const goldLogo = (color: THREE.Color | string) =>
  new THREE.MeshStandardMaterial({
    color: color,
    //   metalness: 0.3,
    metalness: 0.95,
    roughness: 0.4,
    // envMap: null, // Set later for reflections
    side: THREE.DoubleSide,
    //   opacity: opacity, // Set opacity to 50%
    transparent: true, // Make the material transparent
    map: Textures.gold,
  });

export const playLogo = (color: THREE.Color | string) =>
  new THREE.MeshBasicMaterial({
    color: color,
    side: THREE.DoubleSide,
    map: Textures.gold,
    // map: Textures.silver,
  });
