import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import Configs from "configs";

posthog.init(Configs.postHog.instanceId, {
  api_host: Configs.postHog.api_host,
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <PostHogProvider
        client={posthog}
        options={{
          autocapture: {
            dom_event_allowlist: [],
            element_allowlist: [],
            css_selector_allowlist: [],
          },
        }}
      >
        <App />
      </PostHogProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
