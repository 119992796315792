import React from "react";
import LayoutClient from "./client";
import LayoutInstagram from "./instagram";
import LayoutBooks from "./books";
import { useLocation } from "react-router-dom";
import { isItInstagramRoute, isItClaudeRoute, isItAdminRoute, isItLogoRoute } from "routes";
import LayoutAdmin from "./admin";

import { Logo3d } from "services/ThreeTs";

const Layout = () => {
  const { pathname } = useLocation();
  // return <LayoutAdmin />;
  // return <LayoutBooks />;

  if (isItAdminRoute(pathname)) return <LayoutAdmin />;
  if (isItInstagramRoute(pathname)) return <LayoutInstagram />;
  if (isItClaudeRoute(pathname)) return <LayoutClient story="claude" />;
  if (isItLogoRoute(pathname)) return <Logo3d />;
  return <LayoutClient story="portfolio" />;
};

export default Layout;
