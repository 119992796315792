import * as THREE from "three";

const times = [0, 0.05, 0.07];

export const getAnimationPlay = (mesh: THREE.Object3D) => {
  const values = [0, 0, 0, 3, 3, 3, 2.5, 2.5, 2.5];
  // const values = [0, 0, 0, 0.3, 0.3, 0.3, 0.25, 0.25, 0.25];
  const positionKF = new THREE.VectorKeyframeTrack(".position", times, values);

  // just one track for now
  const tracks = [positionKF];

  // use -1 to automatically calculate
  const length = -1;
  const clip = new THREE.AnimationClip("slowmove", length, tracks);

  const mixer = new THREE.AnimationMixer(mesh);
  const action = mixer.clipAction(clip);
  action.setLoop(THREE.LoopOnce, 0);
  action.clampWhenFinished = true;

  return { mixer: mixer, action: action };
};

export const getAnimationSquare = (mesh: THREE.Object3D) => {
  const values = [0, Math.PI / 2, Math.PI, Math.PI * 1.5];
  const positionKF = new THREE.VectorKeyframeTrack(".rotation", times, values);

  // just one track for now
  const tracks = [positionKF];

  // use -1 to automatically calculate
  const length = -1;
  const clip = new THREE.AnimationClip("slowmove", length, tracks);

  const mixer = new THREE.AnimationMixer(mesh);
  const action = mixer.clipAction(clip);
  action.setLoop(THREE.LoopOnce, 0);
  action.clampWhenFinished = true;

  return { mixer: mixer, action: action };
};

export const camaraOnPlay = (mesh: THREE.Object3D) => {
  const currentPos = mesh.position;
  const values = [currentPos.x, currentPos.y, currentPos.z, 20, 20, 20, currentPos.x, currentPos.y, currentPos.z];
  const positionKF = new THREE.VectorKeyframeTrack(".position", times, values);

  // just one track for now
  const tracks = [positionKF];

  // use -1 to automatically calculate
  const length = -1;
  const clip = new THREE.AnimationClip("slowmove", length, tracks);

  const mixer = new THREE.AnimationMixer(mesh);
  const action = mixer.clipAction(clip);
  action.setLoop(THREE.LoopOnce, 0);
  action.clampWhenFinished = true;

  return { mixer: mixer, action: action };
};

function animateCamaraTarget(camera: THREE.Camera, renderer: THREE.Renderer, scene: THREE.Scene) {
  requestAnimationFrame(() => animateCamaraTarget(camera, renderer, scene));

  // Example: Change target position over time
  const time = Date.now() * 0.001;
  const targetX = Math.sin(time) * 5;
  const targetY = Math.cos(time) * 5;
  const targetZ = Math.sin(time) * 2;

  camera.lookAt(new THREE.Vector3(targetX, targetY, targetZ));
  camera.updateMatrixWorld();

  // Render the scene
  renderer.render(scene, camera);
}
