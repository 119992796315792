import React from "react";
import { Button, Icon, Label } from "semantic-ui-react";
import "./styles.scss";
import { useLogo } from "components/Logo/useLogo";
import { useDefiningElements } from "components/FotoCatalog/useDefiningElement";

type Props = {};

const NewElement: React.FC<Props> = () => {
  const points = useDefiningElements();
  const [rotation, rotate] = useLogo();

  const _handlerCreateCLick = () => {
    console.log("_handlerCreateCLick -> rotate PRE");
    (rotate as any)();
    console.log("_handlerCreateCLick -> rotate POST");
  };

  return (
    <div className="new-element">
      <p>
        Name:{" "}
        <Label as="a" tag>
          New element
        </Label>
      </p>
      <p>
        Points: <code>{JSON.stringify(points.map(({ px, py }) => [px, py]))}</code>
      </p>

      <Button icon onClick={_handlerCreateCLick} color="instagram" labelPosition="right">
        Create
        <Icon name="add" />
      </Button>
    </div>
  );
};

// const LOGO_POINT = [[300,600],[691,602],[503,172],[320,600]]

export default NewElement;
