import React, { useEffect, useState, useCallback } from "react";
import { LanguagesDropdown } from "services/translate";
import * as Services from "./services/sections";
import * as Types from "./types";
import "./styles.scss";
import { AnimationProgressBar, FurtherActions } from "./components";
import BubbleChat from "components/bubbleChat";
// import { ThreeTs } from "services/ThreeTs";

// import { logo3dPoints } from "components/Logo/helpers";

type Props = {
  story: string;
  SectionsImp: Services.ImpSections;
  show: {
    chat: boolean;
    furtherActions: boolean;
  };
  frameSizes: { width: number | string; height: number | string; renderAs: string };
};

const LayoutScroll: React.FC<Props> = React.memo(({ story, SectionsImp, show, frameSizes }) => {
  const [progresses, setProgresses] = useState<Types.Progresses>({
    renderValues: Services.ImpSections.scrollLayoutData.map(() => 0),
    currentSeccion: 0,
  });
  // const containerRef = React.useRef<HTMLDivElement>(null);

  const [showState, setShowState] = useState({ ...show, furtherActions: false }); // further action always start hidden.

  const scrollHandler = useCallback(() => {
    const progresses = SectionsImp.progressesRefactored(window.scrollY);
    if (show.furtherActions && progresses[1] > 0.875) {
      setShowState((e) => ({ ...e, furtherActions: true }));
    } else {
      setShowState((e) => ({ ...e, furtherActions: false }));
    }
    setProgresses({ renderValues: progresses, currentSeccion: SectionsImp.current });
  }, [SectionsImp, show.furtherActions]);

  useEffect(() => {
    // if (!containerRef.current) {
    //   return;
    // }

    // for (let i = 1; i < 10; i++) {
    //   new ThreeTs(
    //     {
    //       htmlElement: containerRef.current,
    //       width: containerRef.current.clientWidth / 10,
    //       height: containerRef.current.clientHeight / 10,
    //     },
    //     logo3dPoints(10) as any
    //   );
    // }

    window.addEventListener("scroll", scrollHandler);
    scrollHandler();
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [scrollHandler]);

  // const changeViewport: Types.ViewportChanger = useCallback((viewport) => {
  //   smFrame.smServ.refreshValues({ width: window.innerWidth, height: window.innerHeight }, viewport);
  //   setSmFrame((e) => ({ ...e, smServ: smFrame.smServ }));
  // }, []);

  return (
    <div
      className={"lyt-scroll social-media-wrapper " + frameSizes.renderAs + " sty-" + story}
      style={{ height: `${SectionsImp.totalScrollConsumed}px` }}
    >
      <div className={"sections-wrapper"} style={{ height: `${frameSizes.height}px`, width: `${frameSizes.width}px` }}>
        <AnimationProgressBar
          renderValue={progresses.renderValues[progresses.currentSeccion]}
          width={frameSizes.width}
        />
        <LanguagesDropdown />

        {/* <div
          className={"HERE I M"}
          ref={containerRef}
          style={{
            zIndex: 1234566,
            position: "fixed",
            width: "100%",
            height: "100%",
            left: 0,
            background: "transparent",
          }}
        ></div> */}

        <Sections progresses={progresses} />

        {showState.chat && <BubbleChat />}
        {showState.furtherActions && <FurtherActions story={story} />}
      </div>
    </div>
  );
});

type SectionsProps = { progresses: Types.Progresses };

const Sections: React.FC<SectionsProps> = ({ progresses }) => {
  return (
    <>
      {Services.ImpSections.scrollLayoutData.map((e, i) => {
        const Section = e.section as React.FC<any>;
        return (
          <section
            className={"s-" + e.name + " " + (progresses.currentSeccion === i ? "active" : "")}
            style={{ zIndex: 2 - i }}
          >
            {Section && <Section key={i} renderValue={progresses.renderValues[i]} />}
          </section>
        );
      })}
    </>
  );
};

export default LayoutScroll;
