import * as THREE from "three";
import * as Types from "./types";
import Textures from "./textures";
import { SETTINGS } from "./index";

import { SVGLoader } from "three-stdlib";
import SVGdata from "./logo_2d.svg";
import * as Materials from "./materials";

// const TRANSFORM_POINT = { x: -2, y: -2, z: -2 }; // it would be use to center the logo (it should be the centroide)
const TRANSFORM_POINT = { x: 0, y: 0, z: 0 }; // it would be use to center the logo (it should be the centroide)
export const LOGO_COLORS = {
  exterior: new THREE.Color(0xecbc34),
  square: new THREE.Color(0xce9a05),
  inner: new THREE.Color(0xfdd22b),
  //   play: new THREE.Color(0xf5c51f),
  //   play: new THREE.Color(0x26c923), // active green
  play: new THREE.Color(0x2fff2c), // active green
  origin: new THREE.Color(0xf5c51f),
  boders: new THREE.Color(0x8b4513),
};

const getMesh = (
  points: Types.Points,
  color: THREE.Color | string,
  name?: string,
  opacity: number = 1,
  materialToFill?: THREE.MeshStandardMaterial
) => {
  const pointsToUse = points.map((point) => [
    point[0] + TRANSFORM_POINT.x,
    point[1] + TRANSFORM_POINT.y,
    point[2] + TRANSFORM_POINT.z,
  ]);

  const geometry = new THREE.BufferGeometry();
  const vertices = new Float32Array(pointsToUse.flat());
  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));

  // Example UV coordinates (assuming pointsToUse is a flat array of 2D points)
  const uvs = new Float32Array([
    // ... UV coordinates here ...
    // For example, if you have 4 vertices, you might have:
    0, 0, 1, 0, 1, 1, 0, 1,
    // Repeat as necessary for your vertices
  ]);

  geometry.setAttribute("uv", new THREE.BufferAttribute(uvs, 2));

  // geometry.computeBoundingBox();
  // geometry.computeBoundingSphere();

  const isPlayBtn = name === "play";
  !isPlayBtn && geometry.computeVertexNormals();

  const materialToUSe = isPlayBtn ? Materials.playLogo(color) : Materials.goldLogo(color);

  const filledMesh = new THREE.Mesh(geometry, materialToUSe);
  if (name) {
    filledMesh.name = name;
  }

  const group = new THREE.Group();
  group.add(filledMesh);

  if (isPlayBtn) return group;

  const lineCurves: THREE.LineCurve3[] = [];
  for (let i = 0; i < pointsToUse.length - 1; i++) {
    // const p0 = vertices[i];
    // const p1 = vertices[i + 1];
    lineCurves.push(
      new THREE.LineCurve3(
        new THREE.Vector3(pointsToUse[i][0], pointsToUse[i][1], pointsToUse[i][2]),
        new THREE.Vector3(pointsToUse[i + 1][0], pointsToUse[i + 1][1], pointsToUse[i + 1][2])
      )
    );
  }

  // Use the array of LineCurve3 segments to create the path
  const path = new THREE.CurvePath();
  lineCurves.forEach((curve) => {
    path.add(curve);
  });

  // Create tube geometry along the path
  const tubeSegments = 350; // Number of segments
  const tubeRadius = 0.05; // Radius of the tube

  const tubeGeometry = new THREE.TubeGeometry(path as any, tubeSegments, tubeRadius);
  // const tubeMesh = new THREE.Mesh(tubeGeometry, Materials.goldLogo(LOGO_COLORS.boders));
  const tubeMesh = new THREE.Mesh(tubeGeometry, Materials.playLogo(LOGO_COLORS.boders));

  group.add(tubeMesh);

  SETTINGS.showLines && group.add(new THREE.Mesh(geometry, Materials.wireframe));

  return group;
};

export const getLogoMeshes = (points: Types.Logo3dProps) => {
  const squareMeshes = [
    getMesh(points.square.triangleLeft, LOGO_COLORS.square),
    getMesh(points.square.triangleRight, LOGO_COLORS.square),
    getMesh(points.square.triangleTop, LOGO_COLORS.square),
  ];

  const innerMeshes = [
    getMesh(points.inner.laterals.left, LOGO_COLORS.inner),
    getMesh(points.inner.laterals.bottom, LOGO_COLORS.inner),
    getMesh(points.inner.laterals.right, LOGO_COLORS.inner),
    // getMesh(points.inner.triangle, LOGO_COLORS.play, 1, materialFillPlay),
  ];

  const originMeshes = [
    getMesh(points.origin.laterals.left, LOGO_COLORS.origin, "play"),
    getMesh(points.origin.laterals.bottom, LOGO_COLORS.origin, "play"),
    getMesh(points.origin.laterals.right, LOGO_COLORS.origin, "play"),
    getMesh(points.origin.triangle, LOGO_COLORS.play, "play"),
  ];

  const groups = {
    corners: {
      bottom: [
        getMesh(points.exterior.left.bottom, LOGO_COLORS.exterior),
        getMesh(points.exterior.bottom.left, LOGO_COLORS.exterior),
      ],
      top: [
        getMesh(points.exterior.left.top, LOGO_COLORS.exterior),
        getMesh(points.exterior.right.top, LOGO_COLORS.exterior),
      ],
      right: [
        getMesh(points.exterior.right.bottom, LOGO_COLORS.exterior),
        getMesh(points.exterior.bottom.right, LOGO_COLORS.exterior),
      ],
    },
    origin: originMeshes,
    square: [...squareMeshes, ...innerMeshes],
  };

  const originGroup = new THREE.Group();
  originMeshes.map((mesh) => originGroup.add(mesh));

  const squareGroup = new THREE.Group();
  groups.square.map((mesh) => squareGroup.add(mesh));

  const cornerGroupBottom = new THREE.Group();
  const cornerGroupRight = new THREE.Group();
  const cornerGroupTop = new THREE.Group();

  groups.corners.bottom.map((mesh) => cornerGroupBottom.add(mesh));
  groups.corners.right.map((mesh) => cornerGroupRight.add(mesh));
  groups.corners.top.map((mesh) => cornerGroupTop.add(mesh));

  return {
    origin: originGroup,
    square: squareGroup,
    cornerBottom: cornerGroupBottom,
    cornerRight: cornerGroupRight,
    cornerTop: cornerGroupTop,
    oneMesh: groups.corners.bottom[0],
  };
};

export const setEnvMap = (scene: THREE.Scene) => {
  Textures.logoCreation.mapping = THREE.EquirectangularReflectionMapping;
  //   Textures.logoCreation.mapping = THREE.CubeReflectionMapping;
  scene.environment = Textures.logoCreation;
  //   scene.background = Textures.logoCreation;
  scene.background = new THREE.Color(0xffffff);

  //   const loader = new THREE.CubeTextureLoader();
  //   const texture = loader.load([
  //     White, // positive x
  //     White, // negative x
  //     White, // positive y
  //     White, // negative y
  //     White, // positive z
  //     White, // negative z
  //   ]);
  //   texture.mapping = THREE.CubeReflectionMapping;
  //   scene.background = texture;
  //   scene.environment = texture;
};

export const addEarth = (scene: THREE.Scene) => {
  const sphereGeometry = new THREE.SphereGeometry(5, 32, 32);
  // const sphereMaterial = new THREE.MeshStandardMaterial({ color: LOGO_COLORS.square, map: textureSphere });
  const sphereMaterial = new THREE.MeshStandardMaterial({ map: Textures.earth, metalness: 1, roughness: 0.2 });
  const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
  sphere.position.set(14, 0, 0);
  scene.add(sphere);
};

export const addCube = (scene: THREE.Scene) => {
  const geometry = new THREE.BoxGeometry(6, 6, 6);
  const material = new THREE.MeshStandardMaterial({
    map: Textures.gold,
    // color: 0x888888,
    metalness: 1, // Full metalness
    roughness: 0.2,
  });

  const cube = new THREE.Mesh(geometry, material);
  cube.position.set(-10, 0, 0);
  scene.add(cube);
};

export const addSprite = (scene: THREE.Scene) => {
  const materialBg = new THREE.SpriteMaterial({ map: Textures.me, color: new THREE.Color(1, 1, 1) });
  const sprite = new THREE.Sprite(materialBg);
  sprite.scale.set(1, 1, 1); // Scale sprite to desired size
  sprite.position.set(10, 10, 10);
  scene.add(sprite);
};

const loadSVG = () => {
  // Load and parse the SVG
  const svgLoader = new SVGLoader();
  svgLoader.load(
    //   "assets/shape.svg",
    SVGdata,
    (data) => {
      const paths = data.paths;
      // const material = new THREE.MeshBasicMaterial({ color: 'gray', side: THREE.DoubleSide });
      // const material = new THREE.MeshStandardMaterial({ color: 'rgb(255, 80, 0)', side: THREE.DoubleSide });
      const material = new THREE.MeshStandardMaterial({ color: "white", side: THREE.DoubleSide, wireframe: true });

      paths.forEach((path) => {
        const shapes = SVGLoader.createShapes(path);

        shapes.forEach((shape) => {
          // Scale the shape by manually scaling its points
          // const scaledPoints = shape.getPoints().map((point) => point.multiplyScalar(0.02));
          // const scaledShape = new THREE.Shape(scaledPoints);

          const geometry = new THREE.ExtrudeGeometry(shape, {
            depth: 2,
            bevelEnabled: true,
            bevelThickness: 0.5,
            bevelSize: 1,
            bevelSegments: 2,
          });
          const mesh = new THREE.Mesh(geometry, material);
          //   scene.add(mesh);
          //   svgMeshes.push(mesh); // Store the mesh in the array
        });
      });
    },
    undefined,
    (error) => {
      console.log("Couldn't load the SVG file", error);
      console.error(error);
    }
  );
};
