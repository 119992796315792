import * as THREE from "three";
import Logo3d from "./Logo";
import { OrbitControls } from "three-stdlib";

import * as Animations from "./animations";
import LightsImp from "./lights";

import * as Types from "./types";
import * as Helpers from "./helpers";
import WordsImp from "./words";

export { Logo3d };

export const SETTINGS = {
  showCube: false,
  showEarth: false,
  showGrid: false,
  showLines: true,
  animateRotation: false,
  showAxes: false,
  showLightHelpers: false,
  showEnvMap: false,
  showSprite: false,
  allowOrbitControls: false,
  mouseLightEffect: true,
};

export class ThreeTs {
  constructor(container: { htmlElement: HTMLElement; width: number; height: number }, logo3Dpoints: Types.Logo3dProps) {
    const scene = new THREE.Scene();

    SETTINGS.showEnvMap && Helpers.setEnvMap(scene);

    const renderer = new THREE.WebGLRenderer({
      alpha: false,
      antialias: true,
    });

    renderer.setSize(container.width, container.height);
    renderer.setPixelRatio(Math.min(2, window.devicePixelRatio)); // normally is 1 or 2. More is better quality but bad performance
    renderer.outputColorSpace = THREE.DisplayP3ColorSpace;
    renderer.setClearColor(0x000000, 0); // Set the clear color to transparent
    container.htmlElement.appendChild(renderer.domElement);
    renderer.shadowMap.enabled = false;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Adjust as needed

    // const camera = new THREE.OrthographicCamera(-15, 15, -15, 15, 0.2, 10000);
    const camera = new THREE.PerspectiveCamera(50, container.width / container.height, 0.2, 1000);
    camera.position.set(25, 25, 25);

    const updateCameraLookAt = (target: THREE.Vector3) => {
      camera.lookAt(target);
      camera.updateMatrixWorld();

      // controls.target.copy(target);
      // controls.update(); // Update the controls to apply the new target
    };

    // Set up OrbitControls
    // const controls = new OrbitControls(camera, renderer.domElement);
    // controls.enableDamping = true; // an option for smooth control

    // Initial setup
    updateCameraLookAt(new THREE.Vector3(0, 0, 0));

    // Add AxesHelper
    const axesHelper = new THREE.AxesHelper(15);
    SETTINGS.showAxes && scene.add(axesHelper);

    const Lights = new LightsImp(scene, SETTINGS.showLightHelpers);
    Lights.addLights();

    SETTINGS.showGrid && scene.add(new THREE.GridHelper(100, 100, 0x888888, 0x444444));
    SETTINGS.showCube && Helpers.addCube(scene);
    SETTINGS.showEarth && Helpers.addEarth(scene);
    SETTINGS.showSprite && Helpers.addSprite(scene);

    const meshesGroups = Helpers.getLogoMeshes(logo3Dpoints);
    scene.add(meshesGroups.origin);
    scene.add(meshesGroups.square);
    scene.add(meshesGroups.cornerBottom);
    scene.add(meshesGroups.cornerRight);
    scene.add(meshesGroups.cornerTop);

    const loadingWord = new WordsImp(scene);
    // loadingWord.renderLoadingWord();

    // const { mixer, action } = Animations.getAnimation(playButton);
    const { mixer, action } = Animations.getAnimationPlay(meshesGroups.origin);
    const { mixer: mixerCamara, action: actionCamara } = Animations.camaraOnPlay(camera);

    function onClickPlay() {
      action.reset();
      actionCamara.reset();

      action.play();
      actionCamara.play();

      setTimeout(() => {
        simulateSpacebarPress();
      }, 70);

      renderer.render(scene, camera);
    }

    const clock = new THREE.Clock();

    function firstAnimate() {
      requestAnimationFrame(firstAnimate);

      var delta = clock.getDelta(); // Assuming clock is defined globally
      mixer.update(delta);
      mixerCamara.update(delta);

      renderer.render(scene, camera);
    }
    firstAnimate();

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const isPlayBtnIntersected = () => {
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects([meshesGroups.origin]);
      return intersects.length > 0;
    };

    const onMouseMove = (event: MouseEvent) => {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      const intersected = isPlayBtnIntersected();
      document.body.style.cursor = intersected ? "pointer" : "default";

      // if (intersected) {
      //   ((meshesGroups.origin.children[0] as THREE.Mesh).material as THREE.MeshStandardMaterial).color.set(
      //     LOGO_COLORS.play
      //   );
      // } else {
      //   ((meshesGroups.origin.children[0] as THREE.Mesh).material as THREE.MeshStandardMaterial).color.set(
      //     LOGO_COLORS.inner
      //   );
      // }

      Lights.updateMouseLightPosition(new THREE.Vector3(mouse.x * 10 + 20, mouse.y * 10 + 20, 20));

      // svgMeshes.forEach((mesh) => {
      //   mesh.rotation.x = -1 * 0.1 * mouse.x;
      //   mesh.rotation.y = 0.1 * mouse.y;
      //   mesh.rotation.z = 0.1 * mouse.y;
      // });

      renderer.render(scene, camera);
    };

    SETTINGS.mouseLightEffect && document.addEventListener("mousemove", onMouseMove, false);

    function onClick(event: MouseEvent) {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      const intersected = isPlayBtnIntersected();
      if (intersected) {
        onClickPlay();
      }
    }

    window.addEventListener("click", onClick, false);

    const animate = () => {
      requestAnimationFrame(animate);

      // svgMeshes.forEach((mesh) => {
      //   mesh.rotation.x += 0.0001;
      //   mesh.rotation.y += 0.0001;
      //   mesh.rotation.z += 0.0001;
      // });

      // controls.update();
      renderer.render(scene, camera);
    };

    SETTINGS.animateRotation && animate();
    !SETTINGS.animateRotation &&
      setTimeout(() => {
        renderer.render(scene, camera);
      }, 100);
  }
}

function simulateSpacebarPress() {
  const event = new KeyboardEvent("keydown", {
    key: " ",
    code: "Space",
    keyCode: 32, // Deprecated, but included for compatibility
    charCode: 32, // Deprecated, but included for compatibility
    which: 32, // Deprecated, but included for compatibility
    bubbles: true,
    cancelable: true,
  });

  document.dispatchEvent(event);
}
