import * as d3 from "d3";
import { sleep } from "utils";

type Point = { x: number; y: number };

export class ArrowUnterTitle {
  private lineData: Point[];
  private arrowData: Point[];
  private svgContainer: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  svgClass = "svg__title_underline-line";

  constructor(wrapper: HTMLElement, public origin: Point) {
    const width = wrapper.clientWidth + 10;
    this.lineData = this._getLineData(width);
    this.arrowData = this._getArrowLineData(width);
    const maxY = 70; //lineData.reduce((a, b) => Math.max(a, b.y), -Infinity);
    this.svgContainer = d3
      .select(wrapper)
      .append("svg")
      .attr("class", this.svgClass)
      .attr("style", `height: ${maxY}px; width: calc(100% + 40px)`);
  }

  public animate = async () => {
    this.drawLine();
    await sleep(800);
    this.drawArrow();
  };

  private drawLine = () => {
    const line = d3.line().curve(d3.curveCardinal);

    this.svgContainer
      .append("path")
      .attr("d", line(this.lineData.map((e) => [e.x, e.y])))
      .transition()
      .duration(800)
      .ease(d3.easeLinear)
      .attrTween("stroke-dasharray", function () {
        const length = (this as any).getTotalLength();
        return d3.interpolate(`0,${length}`, `${length},${length}`);
      });
  };

  private drawArrow = () => {
    const line = d3.line().curve(d3.curveCardinal);

    this.svgContainer
      .append("path")
      .attr("d", line(this.arrowData.map((e) => [e.x, e.y])))
      .transition()
      .duration(500)
      .ease(d3.easeLinear)
      .attrTween("stroke-dasharray", function () {
        const length = this.getTotalLength();
        return d3.interpolate(`0,${length}`, `${length},${length}`);
      });
  };

  private _getLineData = (width: number) => {
    const deltaX = width / 4;
    const [xo, yo] = [this.origin.x, this.origin.y];

    const lineData = [
      { x: 0, y: 0 },
      { x: deltaX, y: 5 },
      { x: deltaX * 2, y: 2 },
      { x: deltaX * 3, y: 1 },
      { x: deltaX * 4, y: 2 },
    ];

    return lineData.map(({ x, y }) => ({ x: x + xo, y: y + yo }));
  };

  private _getArrowLineData = (width: number) => {
    const arrowWidth = 15;
    const arrowHeight = 20;
    const arrowGapWithLine = 10;
    const [xo, yo] = [this.origin.x + width + arrowGapWithLine, this.origin.y];

    const lineData = [
      { x: -1 * arrowWidth, y: -arrowHeight / 2 },
      { x: (-1 * arrowWidth) / 2, y: -arrowHeight / 4 },
      { x: 0, y: 1 },
      { x: (-1 * arrowWidth) / 2, y: arrowHeight / 4 },
      { x: -1 * arrowWidth, y: arrowHeight / 2 },
    ];

    return lineData.map(({ x, y }) => ({ x: x + xo, y: y + yo }));
  };
}
